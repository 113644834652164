// Adapted from https://github.com/amorriscode/use-overflow/blob/master/src/index.tsx and https://stackoverflow.com/questions/52169520/how-can-i-check-whether-line-clamp-is-enabled

import { type MutableRefObject, type ReactNode, useEffect, useState } from 'react'

export function useIsTextClamped({
  ref,
  text,
}: {
  ref: MutableRefObject<HTMLDivElement | null>
  text: ReactNode
}) {
  const [clamped, setClamped] = useState(false)

  const _windowSize = useWindowSize()

  useEffect(() => {
    _windowSize

    if (!ref.current) {
      setClamped(false)

      return
    }

    const { scrollHeight, clientHeight } = ref.current

    setClamped(scrollHeight > clientHeight)
  }, [ref, text, _windowSize])

  return clamped
}

// adapted from https://github.com/amorriscode/use-overflow/blob/master/src/useWindowSize.ts
function useWindowSize(): { height: number | undefined; width: number | undefined } {
  const isClient = typeof window === 'object'

  function getSize(): { height: number | undefined; width: number | undefined } {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect((): any => {
    if (!isClient) {
      return false
    }

    function handleResize(): void {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)

    return (): void => window.removeEventListener('resize', handleResize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return windowSize
}
