import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    @supports (font-variation-settings: normal) {
        html {
            font-family: "Source Sans Pro", "Helvetica", sans-serif;
        }
    }

    html {
        height: 100%;
        font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "sans-serif";
    }

    body {
        min-height: 100%;
        margin: 0;
        display: flex;
        position: relative;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    html,
    body {
        background-color: ${({ theme }) => theme.cuiColors.background};
        color: ${({ theme }) => theme.cuiColors.text};

        font-family: ${({ theme }) => theme.cuiFonts.regular};
        font-weight: ${({ theme }) => theme.cuiFontWeights.regular};
    }  

    strong {
        font-weight: ${({ theme }) => theme.cuiFontWeights.semibold};
    }

    #root {
        flex: 1;
        min-width: 0;
    }
`
