import {
  Fragment,
  type FunctionComponent,
  type PropsWithChildren,
  type ReactNode,
  useEffect,
  useRef,
} from 'react'

type CuiScrollToBottomListenerProps = {
  onScrollToEnd: () => void
}

const CuiScrollToBottomListener: FunctionComponent<
  PropsWithChildren<CuiScrollToBottomListenerProps>
> = ({ onScrollToEnd }) => {
  const listenerRef = useRef(null)

  useEffect(() => {
    if (listenerRef.current) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            onScrollToEnd()
          }
        },
        { rootMargin: '0px', threshold: 0.25 }
      )

      observer.observe(listenerRef.current)

      return () => observer.disconnect()
    }
  }, [onScrollToEnd])

  return <div ref={listenerRef} />
}

type Props = {
  hasNextPage?: boolean
  isLoading: boolean
  isFetchingNextPage: boolean
  fetchNextPage: () => void
  loader: ReactNode
}

export const CuiInfiniteLoader: FunctionComponent<PropsWithChildren<Props>> = ({
  hasNextPage = false,
  isLoading,
  isFetchingNextPage,
  loader,
  fetchNextPage,
}) => {
  return (
    <Fragment>
      {(isLoading || isFetchingNextPage) && loader}

      {hasNextPage && !isLoading && !isFetchingNextPage && (
        <Fragment>
          <CuiScrollToBottomListener onScrollToEnd={fetchNextPage} />
          {loader}
        </Fragment>
      )}
    </Fragment>
  )
}
