import { useContext } from 'react'
import { Droppable, type DroppableProps as ReactBeautifulDroppableProps } from 'react-beautiful-dnd'

import { CuiDragDropDataContext } from './CuiDragDropContext'

// Docs: github.com/atlassian/react-beautiful-dnd/blob/master/docs/api/droppable.md

type Props = Pick<
  ReactBeautifulDroppableProps,
  /**
   * render function for the droppable elements
   *
   * type: (provided, snapshot) => React.ReactElement
   */
  | 'children'
  /**
   * id of the droppable element
   *
   * type: string
   */
  | 'droppableId'
  /**
   * used to control what draggables can be dropped into the droppable (only if they have the same type)
   * draggables always inherit the droppable's type they are defined in
   *
   * @default 'DEFAULT'
   */
  | 'type'
  /**
   * `virtual` if the droppable list is a virtual list
   *
   * @default 'standard'
   * type: 'standard' | 'virtual'
   */
  | 'mode'
  /**
   * the direction in which items flow in this droppable
   *
   * @default 'vertical'
   * type: 'vertical' | 'horizontal'
   */
  | 'direction'
> & {
  /**
   * `true` if you cannot drag items into this droppable
   *
   * type: boolean | undefined
   */
  isDropDisabled?: boolean | ((currDraggedId: string) => boolean)
}

export const CuiDroppable = ({ children, droppableId, isDropDisabled, ...restProps }: Props) => {
  const { currDraggedId } = useContext(CuiDragDropDataContext)

  return (
    <Droppable
      droppableId={droppableId}
      isDropDisabled={
        typeof isDropDisabled === 'boolean' ? isDropDisabled : isDropDisabled?.(currDraggedId)
      }
      {...restProps}
    >
      {children}
    </Droppable>
  )
}
