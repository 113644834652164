import { type FunctionComponent, useState } from 'react'
import { CuiFlexGroup, CuiInput, CuiPad, CuiText } from 'src/cui/components'

import { type PlannerHeaderProps, PlannerHeaderWrapper, PlannerPageWrapper } from '../Planner'
import { MajorPlan } from './MajorPlan'

const MajorPlannerHeader: FunctionComponent<PlannerHeaderProps> = ({
  searchValue,
  onSearchValueChange,
}) => {
  return (
    <PlannerHeaderWrapper>
      <CuiPad leftSize='m' verticalSize='l'>
        <CuiFlexGroup alignItems='center'>
          <CuiPad rightSize='xl'>
            <CuiText size='title2'>Major Planner</CuiText>
          </CuiPad>
          <CuiInput
            placeholder='Add requirement section'
            iconType='search'
            iconSide='left'
            onChange={onSearchValueChange}
            value={searchValue}
          />
        </CuiFlexGroup>
      </CuiPad>
    </PlannerHeaderWrapper>
  )
}

const MajorPlannerImpl = () => {
  const [searchValue, setSearchValue] = useState('')

  return (
    <PlannerPageWrapper>
      <MajorPlannerHeader searchValue={searchValue} onSearchValueChange={setSearchValue} />
      <MajorPlan planId='12345' />
    </PlannerPageWrapper>
  )
}

export const MajorPlanner = {
  MajorPlanner: MajorPlannerImpl,
}
