export function getSafeExternalLinkProps({ href }: { href?: string }) {
  if (typeof href !== 'string') {
    return {}
  }

  if (!isExternalURL(href)) {
    return {}
  }

  return {
    target: '_blank',
    rel: 'noopener noreferrer',
  }
}

// adapted from https://stackoverflow.com/questions/6238351/fastest-way-to-detect-external-urls
function isExternalURL(href: string) {
  try {
    const { origin } = new URL(href, location.href)

    return origin !== location.origin
  } catch (_error) {
    return true
  }
}
