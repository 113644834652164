import { type FunctionComponent, type PropsWithChildren } from 'react'
import Skeleton, { type SkeletonProps } from 'react-loading-skeleton'

type Props = SkeletonProps

export const CuiLoadingSkeleton: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  ...restProps
}) => {
  return <Skeleton {...restProps}>{children}</Skeleton>
}
