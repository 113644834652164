import * as RadixLabel from '@radix-ui/react-label'
import * as RadixRadioGroup from '@radix-ui/react-radio-group'
import { type FunctionComponent, type PropsWithChildren } from 'react'
import styled from 'styled-components'

import { CuiFlexGroup } from '../CuiFlexGroup'
import { CuiIcon } from '../CuiIcon'
import { CuiPad } from '../CuiPad'

/**
 *
 * Type for item in a radio group
 */
type RadioItemSetting = {
  /**
   *
   * Display label of the radio item
   */
  label: string

  /**
   *
   * Unique value for a label
   */
  value: string

  /**
   *
   * Disabled boolean for whether or not radio item id siabled
   *
   * @default false
   */
  disabled?: boolean
}

/**
 *
 * Props for <CuiRadioGroup>
 */
type RadioGroupProps = {
  /**
   *
   * Callback for when radio item is clicked
   */
  onValueChange: (value: string) => void

  /**
   *
   * Array of items in the radio group
   */
  items: RadioItemSetting[]

  /**
   *
   * Controlled value of the radio item to check
   */
  value: string | null
}

/**
 *
 * Props for <CuiRadioGroupItem>
 */
type RadioGroupItemProps = RadioItemSetting & {
  /**
   *
   * This comopnent cannot have `children`
   */
  children?: never
}

const CuiRadioGroupItemContent = styled(RadixRadioGroup.Item)`
  all: unset;
  backgroundcolor: white;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.cuiColors.darkShade};
  }
`

const CuiRadioIndicator = styled(RadixRadioGroup.Indicator)`
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
`

const CuiRadioLabel = styled(RadixLabel.Root)<{ disabled: boolean | undefined }>`
  color: ${({ theme, disabled }) => (disabled ? theme.cuiColors.darkShade : theme.cuiColors.text)};
`

/**
 *
 * Radio button items within group, low level one row radio button
 */
export const CuiRadioGroupItem: FunctionComponent<PropsWithChildren<RadioGroupItemProps>> = ({
  label,
  value,
  disabled,
}) => {
  return (
    <CuiPad bottomSize='m'>
      <CuiFlexGroup alignItems='center'>
        <CuiRadioGroupItemContent value={value} id={value} disabled={disabled}>
          <CuiIcon type='radio-empty' size='m' />
          <CuiRadioIndicator>
            <CuiIcon type='radio-filled' size='m' color='accent' />
          </CuiRadioIndicator>
        </CuiRadioGroupItemContent>
        <CuiPad leftSize='xs'>
          <CuiRadioLabel htmlFor={value} disabled={disabled}>
            {label}
          </CuiRadioLabel>
        </CuiPad>
      </CuiFlexGroup>
    </CuiPad>
  )
}

/**
 *
 * Radio button group
 */
export const CuiRadioGroup: FunctionComponent<PropsWithChildren<RadioGroupProps>> = ({
  onValueChange,
  items,
  value,
}) => {
  return (
    <RadixRadioGroup.Root value={value ?? undefined} onValueChange={onValueChange}>
      {items.map(({ label, value, disabled = false }: RadioItemSetting) => (
        <CuiRadioGroupItem key={value} label={label} value={value} disabled={disabled} />
      ))}
    </RadixRadioGroup.Root>
  )
}
