import {
  Fragment,
  type FunctionComponent,
  type PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { type DraggableLocation } from 'react-beautiful-dnd'
import { type APISchemas } from 'src/api/api'
import { usePlans } from 'src/api/plan'
import type { CuiDropResult } from 'src/cui/components'
import {
  CuiDragDropContext,
  CuiFlexGrid,
  CuiFlexGroup,
  CuiInput,
  CuiPad,
  CuiText,
} from 'src/cui/components'
import styled from 'styled-components'

import FAKE_DATA from './data.json'
import { PlanQuarter } from './PlanQuarter'

export const PlannerPageWrapper = styled.div`
  background-color: ${({ theme }) => theme.cuiColors.lighterShade};
  height: 100%;
`

export const PlannerHeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.cuiColors.background};
`

export type PlannerHeaderProps = {
  /**
   *
   * header search value
   */
  searchValue: string

  /**
   *
   */
  onSearchValueChange: (value: string) => void
}

const PlannerHeader: FunctionComponent<PropsWithChildren<PlannerHeaderProps>> = ({
  searchValue,
  onSearchValueChange,
}) => {
  return (
    <PlannerHeaderWrapper>
      <CuiPad leftSize='m' verticalSize='l'>
        <CuiFlexGroup alignItems='center'>
          <CuiPad rightSize='xl'>
            <CuiText size='title2'>Your 4-Year Plan</CuiText>
          </CuiPad>
          <CuiInput
            placeholder='Add courses'
            iconType='search'
            iconSide='left'
            onChange={onSearchValueChange}
            value={searchValue}
          />
        </CuiFlexGroup>
      </CuiPad>
    </PlannerHeaderWrapper>
  )
}

type PlannerBodyProps = {
  plans: any
}

export type TermCourses = {
  season: string
  // TODO: properly type
  courses: { id: string; meta_offering: APISchemas['MetaOffering'][]; term: any }[]
  endYear: string
}

const PlannerBody: FunctionComponent<PlannerBodyProps> = ({ plans }) => {
  return (
    <CuiPad size='m'>
      {plans?.map((plan: any) => {
        return (
          <Fragment key={plan.id}>
            <CuiPad bottomSize='s'>
              <CuiText size='title4'>{plan.name}</CuiText>
            </CuiPad>
            <CuiPad bottomSize='m'>
              <CuiFlexGrid columns={4} key={plan.id}>
                {Object.keys(plan.terms).map((termId) => {
                  return (
                    <PlanQuarter
                      id={`${plan.id},${termId}`}
                      key={termId}
                      termCourses={plan.terms[termId]}
                    />
                  )
                })}
              </CuiFlexGrid>
            </CuiPad>
          </Fragment>
        )
      })}
    </CuiPad>
  )
}

const PlannerImpl = () => {
  const { data, isLoading } = usePlans()

  const transformPlannerData = useCallback((plans: any) => {
    const transform = plans?.map((plan: any) => {
      return {
        id: plan.id,
        name: plan.name,
        terms: getTermCourses(plan),
      }
    })

    return transform
  }, [])

  const [searchValue, setSearchValue] = useState('')
  const [plannerData, setPlannerData] = useState(transformPlannerData(FAKE_DATA))

  useEffect(() => {
    setPlannerData(transformPlannerData(FAKE_DATA))
  }, [data, setPlannerData, transformPlannerData])

  if (isLoading) {
    return null
  }

  const onDragEnd = (result: CuiDropResult) => {
    const { destination, source } = result

    const [sourcePlanId, sourceTermId] = source.droppableId.split(',')
    const [destinationPlanId, destinationTermId] = destination.droppableId.split(',')

    const sourcePlanIndex = plannerData.findIndex(
      (plannerDatum: any) => plannerDatum.id === sourcePlanId
    )
    const destinationPlanIndex = plannerData.findIndex(
      (plannerDatum: any) => plannerDatum.id === destinationPlanId
    )

    if (sourcePlanIndex === -1 || destinationPlanIndex === -1) {
      return
    }

    const startColumn = plannerData[sourcePlanIndex].terms[sourceTermId].courses
    const finishColumn = plannerData[destinationPlanIndex].terms[destinationTermId].courses

    if (startColumn === finishColumn) {
      moveSameColumnItem({
        termId: sourceTermId,
        planIndex: sourcePlanIndex,
        column: startColumn,
        sourceIndex: source.index,
        destinationIndex: destination.index,
      })
    } else {
      moveDifferentColumnItem({
        startColumn,
        finishColumn,
        source,
        destination,
        sourcePlanIndex,
        destinationPlanIndex,
      })
    }
  }

  return (
    <PlannerPageWrapper>
      <CuiDragDropContext onDragEnd={onDragEnd}>
        <PlannerHeader searchValue={searchValue} onSearchValueChange={setSearchValue} />
        <PlannerBody plans={plannerData} />
      </CuiDragDropContext>
    </PlannerPageWrapper>
  )

  function getTermCourses(plan: any) {
    const { courses } = plan
    const termClasses: { [term_id: string]: TermCourses } = {}

    const termYearSet = new Set()
    for (const course of courses) {
      termYearSet.add(course.term.endYear)
      const termId = course.term.id
      if (!termClasses[termId]) {
        termClasses[termId] = {
          season: course.term.season,
          courses: [],
          endYear: course.term.endYear,
        }
      }

      termClasses[termId].courses.push(course)
    }

    return termClasses
  }

  function reorder({ list, startIndex, endIndex }: { list: any; startIndex: number; endIndex: number }) {
    const result = [...list]
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  function move({
    source,
    destination,
    droppableSource,
    droppableDestination,
  }: {
    source: any
    destination: any
    droppableSource: DraggableLocation
    droppableDestination: DraggableLocation
  }) {
    const sourceClone = [...source]
    const destClone = [...destination]
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)

    const result: { [key: string]: any } = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone

    return result
  }

  function moveSameColumnItem({
    column,
    sourceIndex,
    destinationIndex,
    planIndex,
    termId,
  }: {
    column: any
    sourceIndex: number
    destinationIndex: number
    planIndex: number
    termId: string
  }) {
    const newColumnItems = reorder({
      list: column,
      startIndex: sourceIndex,
      endIndex: destinationIndex,
    })

    const newPlannerData = [...plannerData]
    newPlannerData[planIndex].terms[termId].courses = newColumnItems
    setPlannerData(newPlannerData)
  }

  function moveDifferentColumnItem({
    startColumn,
    finishColumn,
    source,
    destination,
    sourcePlanIndex,
    destinationPlanIndex,
  }: {
    startColumn: any
    finishColumn: any
    source: DraggableLocation
    destination: DraggableLocation
    sourcePlanIndex: number
    destinationPlanIndex: number
  }) {
    const result = move({
      source: startColumn,
      destination: finishColumn,
      droppableSource: source,
      droppableDestination: destination,
    })
    const sourceTermId = source.droppableId.split(',')[1]
    const destinationTermId = destination.droppableId.split(',')[1]

    const newPlannerData = [...plannerData]
    newPlannerData[sourcePlanIndex].terms[sourceTermId].courses = result[source.droppableId]
    newPlannerData[destinationPlanIndex].terms[destinationTermId].courses =
      result[destination.droppableId]

    setPlannerData(newPlannerData)
  }
}

export const Planner = {
  Planner: PlannerImpl,
}
