import { useState } from 'react'
import styled from 'styled-components'

import { CuiButton } from '../CuiButton'
import { CuiDropdown } from '../CuiDropdown'
import { CuiFlexGroup } from '../CuiFlexGroup'
import { CuiIcon } from '../CuiIcon'
import { CuiInput } from '../CuiInput'
import { CuiPad } from '../CuiPad'
import { CuiSpacer } from '../CuiSpacer'
import { CuiText } from '../CuiText'

export const FeedbackWrapper = styled.div`
  border: '1px solid'
  color: 'darkShade'
  borderRadius: '8px'
  padding-left: 32px;
  position: 'absolute'
  width: 400px;
  position: fixed;
  z-index: 500;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.cuiColors.lightestShade};
  padding: 30px 22px;
  border-radius: 8px;
`

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 499;
`

export type FeedbackOptions = 'Issue' | 'Question' | 'Idea' | 'Praise' | 'Other'
const feedbackOptions: FeedbackOptions[] = ['Issue', 'Question', 'Idea', 'Praise', 'Other']

export const CuiFeedback = ({ onClick }: { onClick: () => void }) => {
  const [message, setMessage] = useState('')
  const [email, setEmail] = useState('')
  const [feedbackOption, setFeedbackOption] = useState<FeedbackOptions>('Issue')

  return (
    <Backdrop>
      <FeedbackWrapper>
        <CuiFlexGroup direction='column' alignItems='stretch'>
          <CuiFlexGroup direction='row' justifyContent='spaceBetween'>
            <CuiText size='title2'> Feedback </CuiText>
            <CuiIcon type='close' onClick={onClick} />
          </CuiFlexGroup>
          <CuiSpacer size='s' as='hr' />

          <CuiFlexGroup direction='row'>
            <CuiText> Select Type of Feedback </CuiText>
            <CuiPad size='xs' />
            <CuiDropdown
              options={feedbackOptions}
              trigger={
                <CuiButton iconSide='right' iconType='caret-down'>
                  {' '}
                  {feedbackOption}{' '}
                </CuiButton>
              }
              getOptionKey={(option) => option}
              getOptionLabel={(option) => option}
              getOptionSelect={(option) => {
                setFeedbackOption(option)
              }}
            />
          </CuiFlexGroup>
          <CuiSpacer size='s' />

          <CuiInput
            value={message}
            onChange={(val) => {
              setMessage(val)
            }}
            placeholder='Type a message...'
          />
          <CuiSpacer size='s' />
          <CuiText> If you’d like a reply, leave your contact info: </CuiText>
          <CuiSpacer size='xs' />
          <CuiInput
            value={email}
            onChange={(val) => {
              setEmail(val)
            }}
            placeholder='Email address (optional)'
          />
          <CuiSpacer size='s' />

          <CuiFlexGroup justifyContent='flexEnd'>
            <CuiButton color='accent'>
              <CuiText color='textOnDarkBackground'>Submit</CuiText>
            </CuiButton>
          </CuiFlexGroup>
        </CuiFlexGroup>
      </FeedbackWrapper>
    </Backdrop>
  )
}
