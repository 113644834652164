import {
  type CuiThemeBase,
  type CuiThemeBreakpoints,
  type CuiThemeFonts,
  type CuiThemeFontSizes,
  type CuiThemeFontWeights,
  type CuiThemeSpacingSizes,
  type CuiThemeStackingLevels,
} from './types'

const cuiBreakpoints: CuiThemeBreakpoints = {
  phablet: 480,
  tablet: 768,
  desktop: 991,
  widescreen: 1280,
}

const cuiFonts: CuiThemeFonts = {
  regular: '"Source Sans Pro", "Helvetica Neue", "Helvetica", "sans-serif"',
}

const cuiFontWeights: CuiThemeFontWeights = {
  regular: 400,
  semibold: 600,
}

const cuiSpacingSizes: CuiThemeSpacingSizes = {
  none: 0,
  xs: 8,
  s: 16,
  m: 24,
  l: 32,
  xl: 48,
  xxl: 64,
}

const cuiFontSizes: CuiThemeFontSizes = {
  titleDisplay: 64,
  title1: 36,
  title2: 24,
  title3: 18,
  title4: 16,
  paragraphDisplay: 32,
  paragraph1: 18,
  paragraph2: 16,
  label: 14,
  caption: 13,
}

const cuiMobileFontSizes: CuiThemeFontSizes = {
  titleDisplay: 43,
  title1: 27,
  title2: 18,
  title3: 16,
  title4: 14,
  paragraphDisplay: 21,
  paragraph1: 16,
  paragraph2: 14,
  label: 12,
  caption: 11,
}

const cuiLineHeights: CuiThemeFontSizes = {
  titleDisplay: 76,
  title1: 48,
  title2: 30,
  title3: 22,
  title4: 19,
  paragraphDisplay: 42,
  paragraph1: 27,
  paragraph2: 24,
  label: 20,
  caption: 17,
}

const cuiMobileLineHeights: CuiThemeFontSizes = {
  titleDisplay: 51,
  title1: 36,
  title2: 23,
  title3: 20,
  title4: 18,
  paragraphDisplay: 28,
  paragraph1: 24,
  paragraph2: 20,
  label: 18,
  caption: 15,
}

const cuiStackingLevels: CuiThemeStackingLevels = {
  dropdown: 1000,
}

export const base: CuiThemeBase = {
  cuiBreakpoints,
  cuiFonts,
  cuiFontWeights,
  cuiFontSizes,
  cuiMobileFontSizes,
  cuiLineHeights,
  cuiMobileLineHeights,
  cuiSpacingSizes,
  cuiStackingLevels,
}
