// https://www.figma.com/file/4Q7WBZxhjwmu9FEbsMEwEY/3.-Color-palette?node-id=1%3A1439

export const palette = {
  // Brand (main) -- Stanford’s “Cardinal red” (70) and “Dark” variant (80)
  brandMain80: '#820000',
  brandMain70: '#8C1515',
  // Brand (light) -- Stanford’s “Cardinal red” Light variant (60) with shades
  brandLight90: '#2A0D11',
  brandLight80: '#541921',
  brandLight70: '#7F2632',
  brandLight60: '#B83A4B',
  brandLight50: '#D33F53',
  brandLight40: '#DC6575',
  brandLight30: '#E58C98',
  brandLight20: '#EDB2BA',
  brandLight10: '#F6D9DD',
  brandLight0: '#FCEEF0',
  // Brand (interactive) -- Stanford’s “Digital red” (60) with shades
  brandInteractive90: '#2C0103',
  brandInteractive80: '#580206',
  brandInteractive70: '#840209',
  brandInteractive60: '#B1040E',
  brandInteractive50: '#CA1620',
  brandInteractive40: '#DC3840',
  brandInteractive30: '#EA686F',
  brandInteractive20: '#F19B9F',
  brandInteractive10: '#F8CDCF',
  brandInteractive0: '#FDE8E9',
  // Neutral
  black: '#000000',
  black15: '#00000026',
  neutral90: '#181817',
  neutral85: '#232323',
  neutral80: '#2F2F2E',
  neutral75: '#3B3B3A',
  neutral70: '#474746',
  neutral60: '#5E5E5D',
  neutral50: '#767674',
  neutral40: '#919190',
  neutral30: '#ADADAC',
  neutral20: '#C8C8C7',
  neutral10: '#E4E4E3',
  neutral0: '#F5F5F5',
  white: '#FFFFFF',
  // Success
  success90: '#042514',
  success80: '#074A28',
  success70: '#0B703B',
  success60: '#0E954F',
  success50: '#12BA63',
  success40: '#47C282',
  success30: '#71D6A1',
  success20: '#A0E3C1',
  success10: '#D0F1E0',
  success0: '#E7F9EF',
  // Warning
  warning90: '#302300',
  warning80: '#614700',
  warning70: '#916A00',
  warning60: '#C28E00',
  warning50: '#F2B100',
  warning40: '#F5C133',
  warning30: '#F7D066',
  warning20: '#FAE099',
  warning10: '#FCEFCC',
  warning0: '#FEF8E6',
  // Error
  error90: '#2D100B',
  error80: '#5A2017',
  error70: '#862F22',
  error60: '#B33F2E',
  error50: '#E04F39',
  error40: '#EE7968',
  error30: '#EC9588',
  error20: '#F3B9B0',
  error10: '#F9DCD7',
  error0: '#FCEBE8',
  // Digital Blue -- Links + interactive states
  digitalBlue90: '#001625',
  digitalBlue80: '#002B4A',
  digitalBlue70: '#00416E',
  digitalBlue60: '#005693',
  digitalBlue50: '#006CB8',
  digitalBlue40: '#258BD4',
  digitalBlue30: '#59A5D9',
  digitalBlue20: '#99C4E3',
  digitalBlue10: '#CCE2F1',
  digitalBlue0: '#E2EFF8',
  // Accent Primary
  accentPrimary90: '#081F1B',
  accentPrimary80: '#103D37',
  accentPrimary70: '#175C52',
  accentPrimary60: '#1F7A6E',
  accentPrimary50: '#279989',
  accentPrimary40: '#52ADA1',
  accentPrimary30: '#7DC2B8',
  accentPrimary20: '#A9D6D0',
  accentPrimary10: '#D4EBE7',
  accentPrimary0: '#E9F6F4',
  // Accent Blue
  accentBlue90: '#001F25',
  accentBlue80: '#003E49',
  accentBlue70: '#005E6E',
  accentBlue60: '#007D92',
  accentBlue50: '#009CB7',
  accentBlue40: '#33B0C5',
  accentBlue30: '#66C4D4',
  accentBlue20: '#99D7E2',
  accentBlue10: '#CCEBF1',
  accentBlue0: '#EEF9FB',
  // Accent Purple
  accentPurple90: '#150F1B',
  accentPurple80: '#2B1F37',
  accentPurple70: '#402E52',
  accentPurple60: '#563E6E',
  accentPurple50: '#6B4D89',
  accentPurple40: '#8971A1',
  accentPurple30: '#A694B8',
  accentPurple20: '#C4B8D0',
  accentPurple10: '#E1DBE7',
  accentPurple0: '#F0ECF3',
  // Accent Velvet
  accentVelvet90: '#19090E',
  accentVelvet80: '#33121D',
  accentVelvet70: '#4C1B2B',
  accentVelvet60: '#66243A',
  accentVelvet50: '#7F2D48',
  accentVelvet40: '#99576D',
  accentVelvet30: '#B28191',
  accentVelvet20: '#CCABB6',
  accentVelvet10: '#E5D5DA',
  accentVelvet0: '#F4EBEE',
  // Accent Orange
  accentOrange90: '#331008',
  accentOrange80: '#662010',
  accentOrange70: '#993117',
  accentOrange60: '#CC411F',
  accentOrange50: '#FF5127',
  accentOrange40: '#FF7452',
  accentOrange30: '#FF977D',
  accentOrange20: '#FFB9A9',
  accentOrange10: '#FFDCD4',
  accentOrange0: '#FFEEEB',
  // Accent Gold
  accentGold90: '#2F1A00',
  accentGold80: '#5D3400',
  accentGold70: '#8C4F00',
  accentGold60: '#BA6900',
  accentGold50: '#E98300',
  accentGold40: '#ED9C33',
  accentGold30: '#F2B566',
  accentGold20: '#F6CD99',
  accentGold10: '#FBE6CC',
  accentGold0: '#FEF3E7',
} as const
