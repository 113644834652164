import { type FunctionComponent, type PropsWithChildren, useState } from 'react'
import { type APISchemas } from 'src/api/api'
import { requirementAbbreviation } from 'src/api/course'
import { CuiDropdown, CuiFilter, CuiFlexGroup, CuiIcon, CuiPad, CuiText } from 'src/cui/components'

type Quarter = APISchemas['TermSeason']
const quarterOptions: Quarter[] = ['Autumn', 'Winter', 'Spring', 'Summer']

type Unit = '1' | '2' | '3' | '4' | '5' | '6'
const unitOptions: Unit[] = ['1', '2', '3', '4', '5', '6']

type Day = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday'
const dayOptions: Day[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

type Time = 'Before 10 AM' | '10 AM - 12 PM' | '12 PM - 2 PM' | '2PM - 5 PM' | 'After 5 PM'
const timeOptions: Time[] = ['Before 10 AM', '10 AM - 12 PM', '12 PM - 2 PM', '2PM - 5 PM', 'After 5 PM']

type Ways = APISchemas['UndergradRequirement']
const waysOptions: Ways[] = [
  'WayAII',
  'WayAQR',
  'WayCE',
  'WayED',
  'WayER',
  'WayFR',
  'WaySI',
  'WaySMA',
  'Language',
  'Write1',
  'Write2',
  'WriteSLE',
  'College',
]

export type SearchType = {
  query?: string
  quarters?: Quarter[]
  units?: Unit[]
  days?: Day[]
  times?: Time[]
  ways?: Ways[]
}

export type HandleFilterParams =
  | { searchFilters: Quarter[]; filterName: 'quarters' }
  | { searchFilters: Unit[]; filterName: 'units' }
  | { searchFilters: Day[]; filterName: 'days' }
  | { searchFilters: Time[]; filterName: 'times' }
  | { searchFilters: Ways[]; filterName: 'ways' }

export type SortOptions = 'Default' | 'Department' | 'Units'
const sortOptions: SortOptions[] = ['Default', 'Department', 'Units']

type Props = {
  selectedOptions: SearchType
  handleFilter: (filteredOutput: HandleFilterParams) => void
  handleSort: (sortOption: SortOptions) => void
}

export const CourseFiltersGroup: FunctionComponent<PropsWithChildren<Props>> = ({
  selectedOptions,
  handleFilter,
  handleSort,
}) => {
  const [sortOption, setSortOption] = useState<SortOptions>('Default')

  const SortDropdown = () => {
    return (
      <CuiDropdown
        trigger={
          <CuiFlexGroup>
            <CuiText>
              Sort: <span style={{ fontWeight: 'bold' }}>{sortOption}</span>
            </CuiText>
            <CuiPad rightSize='xs' />
            <CuiIcon type='caret-down' />
          </CuiFlexGroup>
        }
        options={sortOptions}
        getOptionKey={(option) => option}
        getOptionLabel={(option) => option}
        getOptionSelect={(option) => {
          setSortOption(option)
          handleSort(option)
        }}
      />
    )
  }

  return (
    <CuiPad topSize='s'>
      <CuiFlexGroup justifyContent='spaceBetween'>
        <CuiFlexGroup gutterSize='s'>
          <CuiFilter
            label='Quarters'
            options={quarterOptions}
            selectedOptions={selectedOptions.quarters ?? []}
            setSelectedOptions={(searchFilters) => {
              handleFilter({ searchFilters, filterName: 'quarters' })
            }}
            getOptionKey={(option) => option}
            getOptionLabel={(option) => option}
          />

          <CuiFilter
            label='Units'
            options={unitOptions}
            selectedOptions={selectedOptions.units ?? []}
            setSelectedOptions={(searchFilters) => handleFilter({ searchFilters, filterName: 'units' })}
            getOptionKey={(option) => option}
            getOptionLabel={(option) => (option !== '1' ? `${option} units` : `${option} unit`)}
          />
          <CuiFilter
            label='Days'
            options={dayOptions}
            selectedOptions={selectedOptions.days ?? []}
            setSelectedOptions={(searchFilters) => handleFilter({ searchFilters, filterName: 'days' })}
            getOptionKey={(option) => option}
            getOptionLabel={(option) => option}
          />

          <CuiFilter
            label='Time Offered'
            options={timeOptions}
            selectedOptions={selectedOptions.times ?? []}
            setSelectedOptions={(searchFilters) => {
              handleFilter({ searchFilters, filterName: 'times' })
            }}
            getOptionKey={(option) => option}
            getOptionLabel={(option) => option}
          />

          <CuiFilter
            label='WAYS'
            options={waysOptions}
            selectedOptions={selectedOptions.ways ?? []}
            setSelectedOptions={(searchFilters) => handleFilter({ searchFilters, filterName: 'ways' })}
            getOptionKey={(option) => option}
            getOptionLabel={(option) => requirementAbbreviation[option]}
          />
        </CuiFlexGroup>
        <CuiFlexGroup>
          <SortDropdown />
        </CuiFlexGroup>
      </CuiFlexGroup>
    </CuiPad>
  )
}
