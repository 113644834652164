import React, { createContext, useContext, useEffect, useState } from 'react'
import useKonami from 'react-use-konami'

import { CuiThemeProvider } from '../cui/CuiThemeProvider'

const IS_DARK_THEME_ON_LOCAL_STORAGE_KEY = 'IS_DARK_THEME_ON_LOCAL_STORAGE_KEY'

export const ThemeContext = createContext({
  isDarkThemeOn: false,
  toggleTheme: () => {},
})

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [isDarkThemeOn, setIsDarkThemeOn] = useState(() => {
    const storedTheme = localStorage.getItem('IS_DARK_THEME_ON_LOCAL_STORAGE_KEY')

    return storedTheme
      ? storedTheme === 'true'
      : window.matchMedia('(prefers-color-scheme: dark)').matches
  })

  useEffect(() => {
    localStorage.setItem('IS_DARK_THEME_ON_LOCAL_STORAGE_KEY', isDarkThemeOn.toString())
  }, [isDarkThemeOn])

  const toggleTheme = () => {
    setIsDarkThemeOn(!isDarkThemeOn)
  }

  // for internal use only
  const setDark = () => {
    localStorage.setItem(IS_DARK_THEME_ON_LOCAL_STORAGE_KEY, (!isDarkThemeOn).toString())
    setIsDarkThemeOn(!isDarkThemeOn)
  }

  useKonami(() => setDark(), {
    code: ['ArrowUp', 'ArrowDown', 'Enter'],
  })

  return (
    <ThemeContext.Provider value={{ isDarkThemeOn, toggleTheme }}>
      <CuiThemeProvider theme={isDarkThemeOn ? 'cui-dark' : 'cui-light'}>{children}</CuiThemeProvider>
    </ThemeContext.Provider>
  )
}

export const useTheme = () => useContext(ThemeContext)
