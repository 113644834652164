import { Draggable, type DraggableProps as ReactBeautifulDraggableProps } from 'react-beautiful-dnd'

// Docs: github.com/atlassian/react-beautiful-dnd/blob/master/docs/api/draggable.md

type Props = Pick<
  ReactBeautifulDraggableProps,
  /**
   * render function for the draggable elements
   *
   * type: (provided, snapshot, rubric) => React.ReactElement
   */
  | 'children'
  /**
   * id of the draggable item
   *
   * type: string
   */
  | 'draggableId'
  /**
   * index of the draggable item within the list
   *
   * type: number
   */
  | 'index'
  /**
   * `true` if you don't want the item to be dragged
   *
   * type: boolean or undefined
   */
  | 'isDragDisabled'
>

export const CuiDraggable = ({ children, draggableId, index, ...restProps }: Props) => {
  return (
    <Draggable draggableId={draggableId} index={index} {...restProps}>
      {children}
    </Draggable>
  )
}
