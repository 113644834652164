import { Bar as NivoBar } from '@nivo/bar'
import { type FunctionComponent, type PropsWithChildren, useMemo } from 'react'
import { useTheme } from 'styled-components'

import { ResponsiveWrapper } from '../../internal'

const VERTICAL_LEFT_MARGIN = 25
const VERTICAL_RIGHT_MARGIN = -10
const VERTICAL_TOP_MARGIN = 10

const HORIZONTAL_LEFT_MARGIN = 45
const HORIZONTAL_RIGHT_MARGIN = 6
const HORIZONTAL_TOP_MARGIN = -10

type CuiBarData = {
  /**
   *
   * label of the x-axis portion e.g. 1-5, 5-10
   */
  label: string

  /**
   *
   * height value that corresponds to the label
   */
  value: number
}

type CuiBarTick = {
  /**
   *
   *
   */

  x: number
  /**
   *
   *
   */
  y: number

  /**
   *
   * value of the tick
   */
  value: string
}

type CuiCustomTickProps = {
  /**
   *
   * tick type
   */
  tick: CuiBarTick

  /**
   *
   * label
   */
  label: string

  /**
   *
   * axis
   */
  axis: string

  /**
   *
   * layout
   */
  layout?: string
}

type CuiMultibarChartProps = {
  /**
   *
   * data given to the Bar chart
   */
  data: CuiBarData[]

  /**
   * Orientation of the bar charts
   *
   * @default vertical
   */
  layout?: 'horizontal' | 'vertical'

  /**
   *
   * x-axis label
   */
  xLabel?: string

  /**
   *
   * y-axis label
   */
  yLabel?: string

  /**
   *
   * max Y value of the Bar chart
   */
  maxValue: number
}

type NivoBarProps = CuiMultibarChartProps & {
  /**
   *
   * Forwarded width value to NivoBar chart
   */
  width: number

  /**
   *
   * Forwarded height value to NivoBar chart
   */
  height: number
}

const getColor = ({ id, data }: any) => {
  return data[`${id}Color`]
}

const CustomTick = ({ tick, label, axis, layout }: CuiCustomTickProps) => {
  const theme = useTheme()
  const axisTransform = getAxisTransform()

  if (!(Number(tick.value) % 2 === 0 || Number.isNaN(Number(tick.value)))) {
    return null
  }

  return (
    <g transform={axisTransform}>
      <text
        dominantBaseline='middle'
        style={{
          fill: theme.cuiColors.lighterText,
          fontSize: 12,
          fontWeight: 400,
        }}
        textAnchor='middle'
      >
        {`${tick.value}${label}`}
      </text>
    </g>
  )

  function getAxisTransform() {
    if (layout === 'vertical') {
      return axis === 'x'
        ? `translate(${tick.x},${tick.y + theme.cuiSpacingSizes.s})`
        : `translate(${tick.x - theme.cuiSpacingSizes.xs},${tick.y})`
    }

    return axis === 'x'
      ? `translate(${tick.x},${tick.y + theme.cuiSpacingSizes.xs})`
      : `translate(${tick.x - theme.cuiSpacingSizes.m},${tick.y})`
  }
}

const Bar: FunctionComponent<PropsWithChildren<NivoBarProps>> = ({
  width,
  height,
  layout,
  data,
  xLabel = '',
  yLabel = '',
  maxValue,
}) => {
  const theme = useTheme()
  const transformedData = useMemo(
    () =>
      data.map((datum: CuiBarData) => ({
        ...datum,
        valueColor: theme.cuiVizColors.dataViz01,
        complement: maxValue - datum.value,
        complementColor: theme.cuiColors.lighterShade,
      })),
    [data, theme, maxValue]
  )

  return (
    <NivoBar
      animate={false}
      axisBottom={{
        renderTick: (tick: CuiBarTick) => (
          <CustomTick axis='x' label={xLabel} layout={layout} tick={tick} />
        ),
      }}
      axisLeft={{
        // if we want to manually set up tickValues
        // else controlled by https://github.com/d3/d3-scale
        // tickValues: [0, 10, 20, 30, 40, 50, 60]
        renderTick: (tick: CuiBarTick) => (
          <CustomTick axis='y' label={yLabel} layout={layout} tick={tick} />
        ),
      }}
      isInteractive={false}
      axisRight={null}
      axisTop={null}
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      colors={getColor}
      data={transformedData}
      enableGridY={false}
      enableLabel={false}
      height={height}
      indexBy='label'
      indexScale={{ type: 'band', round: true }}
      keys={['value', 'complement']}
      layout={layout}
      margin={{
        top: layout === 'vertical' ? VERTICAL_TOP_MARGIN : HORIZONTAL_TOP_MARGIN,
        bottom: 25,
        left: layout === 'vertical' ? VERTICAL_LEFT_MARGIN : HORIZONTAL_LEFT_MARGIN,
        right: layout === 'vertical' ? VERTICAL_RIGHT_MARGIN : HORIZONTAL_RIGHT_MARGIN,
      }}
      padding={0.2}
      valueScale={{ type: 'linear' }}
      width={width}
    />
  )
}

export const CuiMultibarChart: FunctionComponent<PropsWithChildren<CuiMultibarChartProps>> = ({
  data,
  layout = 'vertical',
  xLabel,
  yLabel,
  maxValue,
}) => {
  return (
    <ResponsiveWrapper>
      {(width: number, height: number) => (
        <Bar
          data={data}
          height={height}
          width={width}
          layout={layout}
          xLabel={xLabel}
          yLabel={yLabel}
          maxValue={maxValue}
        />
      )}
    </ResponsiveWrapper>
  )
}
