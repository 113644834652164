import { type FunctionComponent, type PropsWithChildren } from 'react'
import { type APISchemas } from 'src/api/api'
import {
  CuiFlexGroup,
  CuiFlexGrow,
  CuiIconPopover,
  CuiMultibarChart,
  CuiPieChart,
  CuiSingleBarChart,
  CuiSpacer,
  CuiText,
} from 'src/cui/components'
import styled from 'styled-components'

type Props = { course: APISchemas['MetaOffering'] }

type DataWrapperProps = {
  flex?: number
}

type CategoryCounts = {
  '<5': number
  '5-10': number
  '10-15': number
  '15-20': number
  '25-30': number
  '30-35': number
  '35+': number
  [key: string]: number
}

const RawHourData = [
  1, 3, 2, 4, 0, 3, 1, 5, 7, 6, 9, 8, 6, 5, 7, 8, 9, 6, 7, 5, 8, 9, 6, 5, 7, 8, 9, 6, 7, 5, 8, 9, 6, 5,
  7, 8, 9, 6, 7, 5, 8, 9, 6, 10, 12, 11, 14, 13, 12, 11, 10, 14, 13, 12, 11, 10, 14, 13, 12, 11, 10, 14,
  13, 12, 11, 10, 15, 17, 16, 19, 18, 17, 16, 15, 18, 17, 16, 15, 18, 17, 16, 15, 25, 27, 26, 29, 28, 27,
  26, 25, 30, 32, 31, 34, 33, 32, 31, 30, 35, 37, 36, 39, 38, 37, 36, 35, 40, 39, 38, 37, 36, 35, 40, 39,
  38, 37, 36, 35,
]

const calculateAverage = (data: number[]): number => {
  const sum = data.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  const average = sum / data.length

  return parseFloat(average.toFixed(2))
}

const averageHours = calculateAverage(RawHourData)

const calculateStandardDeviation = (data: number[]): number => {
  const squaredDifferences = data.map((number) => {
    const difference = number - averageHours

    return difference ** 2
  })
  const meanSquaredDifference = calculateAverage(squaredDifferences)
  const standardDev = Math.sqrt(meanSquaredDifference)

  return parseFloat(standardDev.toFixed(2))
}

const standardDeviation = calculateStandardDeviation(RawHourData)

function categorizeHours(rawData: number[]) {
  const categories: CategoryCounts = {
    '<5': 0,
    '5-10': 0,
    '10-15': 0,
    '15-20': 0,
    '25-30': 0,
    '30-35': 0,
    '35+': 0,
  }

  for (const hour of rawData) {
    if (hour < 5) {
      categories['<5'] += 1
    } else if (hour < 10) {
      categories['5-10'] += 1
    } else if (hour < 15) {
      categories['10-15'] += 1
    } else if (hour < 20) {
      categories['15-20'] += 1
    } else if (hour < 30) {
      categories['25-30'] += 1
    } else if (hour < 35) {
      categories['30-35'] += 1
    } else {
      categories['35+'] += 1
    }
  }

  return Object.keys(categories).map((key) => ({
    label: key,
    value: categories[key],
  }))
}

const HourData = categorizeHours(RawHourData)

const EnrollmentData = [
  {
    label: 'Other',
    value: 23,
  },
  {
    label: 'Coterm',
    value: 50,
  },
  {
    label: 'Senior',
    value: 30,
  },
  {
    label: 'Junior',
    value: 10,
  },
  {
    label: 'Soph',
    value: 2,
  },
  {
    label: 'Frosh',
    value: 2,
  },
]

const maxEnrollmentValue = Math.max(...EnrollmentData.map((item) => item.value))

const DataWrapper = styled.div<DataWrapperProps>`
  flex: ${({ flex }) => flex ?? 1};
  border: 1px solid;
  padding: 26px 22px;
  border-color: ${({ theme }) => theme.cuiColors.lightShade};
  border-radius: 4px;
  width: 100%;
`

const TextAlign = styled.div`
  margin-bottom: -2px;
`

const HourDisplay = ({ hours, type }: { hours: number; type: string }) => {
  return (
    <CuiFlexGroup
      direction='column'
      gutterSizeVertical='xs'
      justifyContent='flexEnd'
      alignItems='flexStart'
    >
      <CuiFlexGroup alignItems='flexEnd'>
        <TextAlign>
          <CuiText size='title2'>{hours}</CuiText>
        </TextAlign>
        <CuiText size='title3'>&nbsp;hrs</CuiText>
      </CuiFlexGroup>
      <CuiText>{type}</CuiText>
    </CuiFlexGroup>
  )
}

const HoursSpent = () => {
  return (
    <DataWrapper>
      <CuiFlexGroup direction='column' alignItems='stretch'>
        <CuiFlexGroup justifyContent='spaceBetween'>
          <CuiFlexGroup gutterSize='s'>
            <CuiText size='title3'>Hours spent on course</CuiText>
            <CuiText size='caption'> (4100 students)</CuiText>
          </CuiFlexGroup>
          <CuiIconPopover
            content='Reported time spent on the course, including class meetings, from students who completed the course in the past 2 years.'
            iconType='info'
            side='bottom'
            align='end'
          />
        </CuiFlexGroup>
        <CuiSpacer size='xs' />
        <CuiMultibarChart data={HourData} layout='vertical' yLabel='%' maxValue={100} />
        <CuiSpacer as='hr' />
        <CuiFlexGroup alignItems='flexStart' justifyContent='spaceBetween'>
          <HourDisplay hours={averageHours} type='average' />
          <HourDisplay hours={standardDeviation} type='standard deviation' />
        </CuiFlexGroup>
      </CuiFlexGroup>
    </DataWrapper>
  )
}

const EnrollmentOutcomes = () => {
  return (
    <DataWrapper>
      <CuiFlexGroup justifyContent='spaceBetween'>
        <CuiFlexGroup gutterSize='s'>
          <CuiText size='title3'>Enrollment outcomes</CuiText>
          <CuiText size='caption'> (4100 students)</CuiText>
        </CuiFlexGroup>
        <CuiIconPopover content='TBD' iconType='info' side='bottom' align='end' />
      </CuiFlexGroup>
      <CuiSpacer />
      <CuiSingleBarChart primaryLabel='Completed' primaryPercentage={59} secondaryLabel='Withdrew' />
    </DataWrapper>
  )
}

const EnrollmentByYear = () => {
  return (
    <DataWrapper>
      <CuiFlexGroup direction='column' alignItems='stretch'>
        <CuiFlexGroup direction='row' justifyContent='spaceBetween'>
          <CuiFlexGroup gutterSize='s'>
            <CuiText size='title3'>Enrollment by year</CuiText>
            <CuiText size='caption'> (4100 students)</CuiText>
          </CuiFlexGroup>
          <CuiIconPopover content='TBD' iconType='info' side='bottom' align='end' />
        </CuiFlexGroup>
        <CuiSpacer size='s' />
        <CuiMultibarChart
          layout='horizontal'
          data={EnrollmentData}
          maxValue={maxEnrollmentValue + 10}
          xLabel=''
        />
      </CuiFlexGroup>
    </DataWrapper>
  )
}

const ReasonsForCourse = () => {
  return (
    <DataWrapper>
      <CuiFlexGroup justifyContent='spaceBetween'>
        <CuiFlexGroup gutterSize='s'>
          <CuiText size='title3'>Reasons for taking course</CuiText>
          <CuiText size='caption'> (4100 students)</CuiText>
        </CuiFlexGroup>
        <CuiIconPopover content='TBD' iconType='info' side='bottom' align='end' />
      </CuiFlexGroup>
      <CuiPieChart
        data={[
          {
            color: 'dataViz02',
            id: 'Major/Minor',
            label: 'Major/Minor',
            value: 32,
          },
          {
            color: 'dataViz03',
            id: 'Reputation',
            label: 'Reputation',
            value: 11,
          },
          {
            color: 'dataViz04',
            id: 'Gen. Ed. Req.',
            label: 'Gen. Ed. Req.',
            value: 5,
          },
          {
            color: 'dataViz05',
            id: 'Other',
            label: 'Other',
            value: 3,
          },
          {
            color: 'dataViz01',
            id: 'Completed',
            label: 'Completed',
            value: 49,
          },
        ]}
      />
    </DataWrapper>
  )
}

export const CourseData: FunctionComponent<PropsWithChildren<Props>> = () => {
  return (
    <CuiFlexGroup
      direction='row'
      gutterSizeHorizontal='s'
      alignItems='flexStart'
      justifyContent='spaceBetween'
    >
      {/* First Column */}
      <CuiFlexGrow>
        <CuiFlexGroup direction='column' gutterSizeVertical='s'>
          <HoursSpent />
          <EnrollmentOutcomes />
        </CuiFlexGroup>
      </CuiFlexGrow>

      {/* Second Column */}
      <CuiFlexGroup direction='column' gutterSizeVertical='s'>
        <EnrollmentByYear />
        <ReasonsForCourse />
      </CuiFlexGroup>
    </CuiFlexGroup>
  )
}
