import * as Switch from '@radix-ui/react-switch'
import { type FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'

export const SwitchRoot = styled(Switch.Root)`
  width: 42px;
  height: 25px;
  background-color: ${({ theme }) => theme.cuiColors.lightestShade};
  border-radius: 9999px;
  border: 1px solid ${({ theme }) => theme.cuiColors.lightShade};
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding: 0px;

  &[data-state='checked'] {
    background-color: ${({ theme }) => theme.cuiColors.accent};
  }
`

export const SwitchThumb = styled(Switch.Thumb)`
  display: block;
  width: 21px;
  height: 21px;
  background-color: ${({ theme }) => theme.cuiColors.darkerShade};
  border-radius: 9999px;
  transition: transform 100ms;
  transform: translateX(1px);
  will-change: transform;

  &[data-state='checked'] {
    transform: translateX(18px);
    background-color: ${({ theme }) => theme.cuiColors.background};
  }
`

type Props = {
  /**
   * Switch ID
   */
  id: string

  /**
   * The click handler.
   */
  onClick?: ((checked: boolean) => void) | undefined

  /**
   * The initial switch state.
   */
  checked?: boolean

  /**
   * When `true`, prevents the user from interacting with the button
   */
  disabled?: boolean
}

export const CuiSwitch: FunctionComponent<Props> = ({ id, onClick, disabled, checked }) => {
  const [internalChecked, setInternalChecked] = useState(checked)

  useEffect(() => {
    if (checked !== undefined) {
      setInternalChecked(checked)
    }
  }, [checked])

  const handleCheckedChange = () => {
    if (onClick) {
      onClick(!internalChecked)
    }

    if (checked === undefined) {
      setInternalChecked(!internalChecked)
    }
  }

  return (
    <SwitchRoot
      id={id}
      checked={internalChecked}
      disabled={disabled}
      onCheckedChange={handleCheckedChange}
    >
      <SwitchThumb />
    </SwitchRoot>
  )
}
