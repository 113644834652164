import { type MakeGenerics, useNavigate, useSearch as useSearchParams } from '@tanstack/react-location'
import { type FunctionComponent, type PropsWithChildren, useState } from 'react'
import { stringifyCourseCode, useSearch } from 'src/api/course'
import {
  CuiFeedback,
  CuiFlexGroup,
  CuiPad,
  CuiProfileDropdown,
  CuiSearch,
  CuiSpacer,
  CuiText,
} from 'src/cui/components'
import styled from 'styled-components'
import { useDebouncedCallback as useDebounce } from 'use-debounce'

import { type SearchType } from './Home/CourseFiltersGroup'
import { ProfileInfo } from './Home/ProfileInfo'
import { loggedInUser } from './Home/ProfileToggle'

const PagePadding = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`

const debounceInterval = 300

const FeedbackButton = styled.div`
  border: none;
  background-color: transparent;
`

// TODO: figure out where this goes -- duplicated in Home.tsx
type SearchRouteParams = MakeGenerics<{
  Search: SearchType
}>

export const DashboardContainer: FunctionComponent<PropsWithChildren<unknown>> = ({ children }) => {
  const selectedOptions = useSearchParams<SearchRouteParams>()

  const [searchValue, setSearchValue] = useState(selectedOptions?.query ?? '')
  const [debouncedSearchValue, setDebouncedValue] = useState(searchValue)
  const { data } = useSearch({ year: 2025 }, { query: debouncedSearchValue }, { enabled: !!searchValue })
  const [isFeedbackVisible, setFeedbackVisible] = useState(false)

  const profileOptions = [
    {
      id: 'userprofile',
      label: 'User Profile',
      component: (
        <ProfileInfo
          initials='MM'
          name='Maria Metz'
          sunetid='jsong526'
          year='Coterm'
          major='Computer Science'
        />
      ),
    },
    {
      id: 'feedback',
      label: 'Give Feedback',
      component: (
        <FeedbackButton onClick={() => setFeedbackVisible(false)}>
          <CuiText>Give Feedback</CuiText>
        </FeedbackButton>
      ),
    },
    'Dark Mode',
    'Logout',
  ]

  // TODO: we probably want to debounce the actual search function used inside the hook
  const setSearchValueDebounced = useDebounce(() => {
    setDebouncedValue(searchValue)
  }, debounceInterval)

  const onChange = (value: string) => {
    setSearchValue(value)
    setSearchValueDebounced()
  }

  const navigate = useNavigate()

  const onSearch = () => {
    navigate({
      search: (prevSearchParams) => ({
        ...prevSearchParams,
        query: searchValue,
      }),
    })
  }

  return (
    <PagePadding>
      <CuiPad horizontalSize='s' verticalSize='s'>
        <CuiFlexGroup justifyContent='spaceBetween'>
          <CuiSearch
            items={data?.entries ?? []}
            onChange={onChange}
            onResultClick={(result) => {
              navigate({ to: result.id })
            }}
            getItemHref={(item) => item.id}
            onSearch={onSearch}
            value={searchValue}
            getItemTitle={(item) => `${stringifyCourseCode(item.courseCode)} ${item.title}`}
            getItemIcon={() => 'book'}
            emptyResultsMessage='No results found'
            placeholder='Search anything...'
          />
          <CuiProfileDropdown
            options={profileOptions}
            trigger={loggedInUser}
            getOptionSelect={(option) => {
              typeof option === 'object' && option.id === 'feedback' && setFeedbackVisible(true)
            }}
            getOptionKey={(option) => (typeof option === 'object' ? option.id : option)}
            getOptionLabel={(option) => (typeof option === 'object' ? option.label : option)}
          />
        </CuiFlexGroup>
      </CuiPad>
      <CuiSpacer as='hr' size='none' />
      {isFeedbackVisible && <CuiFeedback onClick={() => setFeedbackVisible(false)} />}
      {children}
    </PagePadding>
  )
}
