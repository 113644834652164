import 'react-loading-skeleton/dist/skeleton.css'

import { type FunctionComponent, type PropsWithChildren } from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'
import { ThemeProvider } from 'styled-components'

import { GlobalStyles } from '../cui/globalStyles'
import { cuiThemes } from './themes'
import { type CuiThemeName } from './themes/types'

type Props = {
  theme: CuiThemeName
}

export const CuiThemeProvider: FunctionComponent<PropsWithChildren<Props>> = ({
  theme: consumerTheme,
  children,
}) => {
  const theme = cuiThemes[consumerTheme]

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <SkeletonTheme
        baseColor={theme.cuiColors.skeletonBackground}
        highlightColor={theme.cuiColors.skeletonHighlight}
      >
        {children}
      </SkeletonTheme>
    </ThemeProvider>
  )
}
