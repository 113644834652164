import { Link, Outlet, useMatchRoute, useSearch } from '@tanstack/react-location'
import { Fragment, type FunctionComponent, type PropsWithChildren } from 'react'
import { type APISchemas } from 'src/api/api'
import { stringifyCourseCode } from 'src/api/course'
import { useEditMainPlan } from 'src/api/plan'
import { useTerm } from 'src/api/term'
import {
  CuiButton,
  CuiFlexGroup,
  CuiIcon,
  CuiPad,
  CuiSpacer,
  CuiTabs,
  CuiText,
} from 'src/cui/components'

import { CourseData } from './CourseData'
import { CourseOverview } from './CourseOverview'
import { CourseReviews } from './CourseReviews'
import { CourseSchedule } from './CourseSchedule'

type Props = { course: APISchemas['MetaOffering'] }

type CourseDetailTab = 'overview' | 'schedule' | 'data' | 'reviews'

const CourseTabs = {
  overview: CourseOverview,
  schedule: CourseSchedule,
  data: CourseData,
  reviews: CourseReviews,
}

function useAddToCalendar(courseId: string, termId: string): void {
  useEditMainPlan(
    {
      sunetId: 'hhannah',
    },
    {
      metaOfferingId: courseId,
      termAddedId: termId,
    }
  )
}

export const CourseDetail: FunctionComponent<PropsWithChildren<Props>> = ({ course }) => {
  const matchRoute = useMatchRoute()
  const searchParams = useSearch()

  const { courseCode, title, id } = course

  const currentQuarter = parseInt(localStorage.getItem('currentQuarter') ?? '0', 10)
  const endYear = parseInt(localStorage.getItem('endYear') ?? '2025', 10)

  let termId = '3fa85f64-5717-4562-b3fc-2c963f66afa6'
  const termIds: { [key: string]: { id: string } } | undefined = useTerm({ endYear })?.data as
    | { [key: string]: { id: string } }
    | undefined
  if (termIds && currentQuarter >= 0 && currentQuarter < Object.keys(termIds).length) {
    termId = termIds[Object.keys(termIds)[currentQuarter]].id
  }

  const activeTab = getActiveTab()
  const tabs = getTabs()
  const ActiveTabComponent = CourseTabs[activeTab]

  const addToCalendar = useAddToCalendar(id, termId)

  return (
    <Fragment>
      <CuiFlexGroup alignItems='center' justifyContent='spaceBetween'>
        <CuiText size='title1'>{stringifyCourseCode(courseCode)}</CuiText>
        <CuiFlexGroup alignItems='center' gutterSize='s'>
          <CuiButton onClick={() => addToCalendar} color='accent'>
            <CuiIcon type='calendar' color='inverse' />
            <CuiPad horizontalSize='xs' />
            <CuiText color='textOnDarkBackground' size='title4'>
              Plan
            </CuiText>
          </CuiButton>
          <CuiButton onClick={() => {}} color='primary'>
            <CuiIcon type='heart' color='text' />
            <CuiPad horizontalSize='xs' />
            <CuiText color='text' size='title4'>
              Save
            </CuiText>
          </CuiButton>
        </CuiFlexGroup>
      </CuiFlexGroup>
      <CuiSpacer size='s' />
      <CuiText size='title2'>{title}</CuiText>
      <CuiSpacer size='s' />
      <CuiTabs items={tabs} />
      <CuiSpacer size='none' as='hr' />
      <CuiSpacer size='s' />
      <Outlet />
      <ActiveTabComponent course={course} />
    </Fragment>
  )

  function getTabs() {
    return [
      {
        label: 'Overview',
        active: activeTab === 'overview',
        as: Link,
        asProps: {
          to: 'overview',
          search: searchParams,
        },
      },
      {
        label: 'Schedule',
        active: activeTab === 'schedule',
        as: Link,
        asProps: {
          to: 'schedule',
          search: searchParams,
        },
      },
      {
        label: 'Data',
        active: activeTab === 'data',
        as: Link,
        asProps: {
          to: 'data',
          search: searchParams,
        },
      },
      {
        label: 'Reviews',
        active: activeTab === 'reviews',
        as: Link,
        asProps: {
          to: 'reviews',
          search: searchParams,
        },
      },
    ]
  }

  function getActiveTab(): CourseDetailTab {
    if (matchRoute({ to: 'schedule' })) {
      return 'schedule'
    }

    if (matchRoute({ to: 'data' })) {
      return 'data'
    }

    if (matchRoute({ to: 'reviews' })) {
      return 'reviews'
    }

    return 'overview'
  }
}
