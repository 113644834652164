import { type PropsWithChildren } from 'react'
import { getSafeExternalLinkProps } from 'src/cui/utils'
import styled from 'styled-components'

import { type AsProps, type DefaultAsComponentProps } from '../../types/asProps'
import { type CuiTextColor, mixText } from '../CuiText'

type CuiLinkColor = CuiTextColor

type CuiLinkSize = 'paragraph1' | 'paragraph2'

type LinkProps = {
  /**
   *
   * The link.
   *
   * @default false
   */
  href?: string

  /**
   *
   * The size of the text.
   *
   * @default 'paragraph1'
   */
  size?: CuiLinkSize

  /**
   *
   * Color for the link.
   *
   * @default 'inherit'
   */
  color?: CuiLinkColor

  /**
   *
   * A click event handler.
   *
   * @default undefined
   */
  onClick?: () => void
}

/**
 *
 * Props for <CuiLink>.
 */
type Props<AsComponentProps = DefaultAsComponentProps> = PropsWithChildren<LinkProps> &
  AsProps<AsComponentProps>

type CuiLinkRootProps = {
  $color: CuiLinkColor
  $size: CuiLinkSize
  $fontWeight: 'regular'
}

const CuiLinkRoot = styled.a<CuiLinkRootProps>`
  ${mixText}

  text-decoration: underline;
  cursor: pointer;
`

/**
 *
 * <CuiLink> renders a link.
 * `react-router` links can be rendered like `<CuiLink as={Link} asProps={{ to: '/link' }}>`.
 */
export const CuiLink = <AsComponentProps,>({
  href,
  onClick,
  size = 'paragraph1',
  color = 'inherit',
  as = 'a',
  asProps = {} as any,
  ...restProps
}: Props<AsComponentProps>) => {
  return (
    <CuiLinkRoot
      $color={color}
      $size={size}
      $fontWeight='regular'
      href={href}
      as={as}
      onClick={onClick}
      {...getConditionalSafeExternalLinkProps()}
      {...(asProps as any)}
      {...restProps}
    />
  )

  function getConditionalSafeExternalLinkProps() {
    if (as === 'a') {
      return getSafeExternalLinkProps({ href })
    }

    return {}
  }
}
