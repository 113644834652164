import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { AppRoot } from './AppRoot'

const container = document.getElementById('root')

const root = createRoot(container!)

root.render(
  <StrictMode>
    <AppRoot />
  </StrictMode>
)
