import { type FunctionComponent, type MouseEventHandler } from 'react'
import { type CuiThemeColor } from 'src/cui/themes/types'
import { useTheme } from 'styled-components'

import { iconTypeToComponent } from './iconTypeToComponent'

/**
 *
 * Internal to cui. Consuming `CUI_ICON_TYPES` externally is discouraged.
 */
export type CuiIconType = keyof typeof iconTypeToComponent

export const CUI_ICON_TYPES = Object.keys(iconTypeToComponent) as CuiIconType[]

export type CuiIconSize = 's' | 'm' | 'l'

export type CuiIconColorWithoutInherit =
  | 'text'
  | 'accent'
  | 'constructive'
  | 'destructive'
  | 'hushed'
  | 'inverse'

export type CuiIconColor = 'inherit' | CuiIconColorWithoutInherit

export type CuiIconOnClick = MouseEventHandler<SVGSVGElement>

/**
 *
 * Props for <CuiIcon>.
 */
type Props = {
  /**
   *
   * The icon type.
   *
   * @default 'empty'
   */
  type?: CuiIconType

  /**
   *
   * The icon size.
   *
   * @default 'm'
   */
  size?: CuiIconSize

  /**
   *
   * The icon color.
   *
   * @default 'inherit'
   */
  color?: CuiIconColor

  /**
   *
   * This component cannot have `children`.
   */
  children?: never

  /**
   *
   * The click handler.
   */
  onClick?: CuiIconOnClick
}

type IconSizeMap = { [size in CuiIconSize]: number }

const iconSizeMap: IconSizeMap = {
  s: 16,
  m: 20,
  l: 32,
}

type IconColorMap = { [color in CuiIconColorWithoutInherit]: CuiThemeColor }

const iconColorMap: IconColorMap = {
  text: 'text',
  accent: 'accent',
  constructive: 'constructive',
  destructive: 'destructive',
  hushed: 'lighterText',
  inverse: 'textOnDarkBackground',
}

/**
 *
 * `<CuiIcon>` renders icon svgs from the design system
 */
export const CuiIcon: FunctionComponent<Props> = ({
  type = 'empty',
  size = 'm',
  color = 'inherit',
  onClick,
}) => {
  const { cuiColors } = useTheme()

  const IconComponent = iconTypeToComponent[type]

  return (
    <IconComponent
      color={color === 'inherit' ? 'inherit' : cuiColors[iconColorMap[color]]}
      width={iconSizeMap[size]}
      height={iconSizeMap[size]}
      onClick={onClick}
    />
  )
}
