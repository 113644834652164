import { useMediaQuery as useRawMediaQuery } from 'react-responsive'
import { useTheme } from 'styled-components'

type BreakpointType = 'phablet' | 'tablet' | 'desktop' | 'widescreen'

export const useMediaQuery = ({
  minWidth,
  maxWidth,
}: {
  minWidth?: BreakpointType
  maxWidth?: BreakpointType
}) => {
  const theme = useTheme()

  return useRawMediaQuery({
    minWidth: minWidth && theme.cuiBreakpoints[minWidth],
    maxWidth: maxWidth && theme.cuiBreakpoints[maxWidth],
  })
}

export const useIsMobile = () => {
  return useMediaQuery({
    maxWidth: 'tablet',
  })
}
