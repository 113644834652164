import styled from 'styled-components'
import useResizeObserver from 'use-resize-observer/polyfilled'

export interface ResponsiveWrapperProps {
  children: (width: number, height: number) => React.ReactNode
}

const Wrapper = styled.div`
  width: 100%;
`

const PaddingWrapper = styled.div`
  padding-top: 100%;
  position: relative;
`

const ChildrenWrapper = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
  position: absolute;
  top: 0;
`

export const ResponsiveWrapper = (props: ResponsiveWrapperProps) => {
  const { ref, width = 0, height = 0 } = useResizeObserver<HTMLDivElement>()

  return (
    <Wrapper ref={ref}>
      {/* This paddingTop trick forces height to be the same as width */}
      <PaddingWrapper>
        <ChildrenWrapper height={height}>{props.children(width, height)}</ChildrenWrapper>
      </PaddingWrapper>
    </Wrapper>
  )
}
