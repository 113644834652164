import { type FunctionComponent, type PropsWithChildren } from 'react'
import { type CuiThemeSpacingSizes } from 'src/cui/themes/types'
import styled from 'styled-components'

type CuiPadSize = keyof CuiThemeSpacingSizes

/**
 *
 * Props for <CuiPad>.
 */
type Props = {
  /**
   *
   * Padding size on horizontal and vertical axes.
   *
   * @default 'none'
   */
  size?: CuiPadSize

  /**
   *
   * Padding size on horizontal axis.
   *
   * @default the value of the `size` prop, defaults to 'none'.
   */
  horizontalSize?: CuiPadSize

  /**
   *
   * Padding size on vertical axis.
   *
   * @default the value of the `size` prop, defaults to 'none'.
   */
  verticalSize?: CuiPadSize

  /**
   *
   * Left padding size on horizontal axis.
   *
   * @default the value of the `horizontalSize` prop, defaults to 'none'.
   */
  leftSize?: CuiPadSize

  /**
   *
   * Right padding size on horizontal axis.
   *
   * @default the value of the `horizontalSize` prop, defaults to 'none'.
   */
  rightSize?: CuiPadSize

  /**
   *
   * Top padding size on vertical axis.
   *
   * @default the value of the `verticalSize` prop, defaults to 'none'.
   */
  topSize?: CuiPadSize

  /**
   *
   * Bottom padding size on vertical axis.
   *
   * @default the value of the `verticalSize` prop, defaults to 'none'.
   */
  bottomSize?: CuiPadSize

  children?: React.ReactNode
}

type CuiPadRootProps = {
  $leftSize: CuiPadSize
  $rightSize: CuiPadSize
  $topSize: CuiPadSize
  $bottomSize: CuiPadSize
}

const CuiPadRoot = styled.div<CuiPadRootProps>`
  ${({ theme, $leftSize, $rightSize, $topSize, $bottomSize }) => `
  ${$leftSize === 'none' ? '' : `padding-left: ${theme.cuiSpacingSizes[$leftSize]}px;`}
  ${$rightSize === 'none' ? '' : `padding-right: ${theme.cuiSpacingSizes[$rightSize]}px;`}
  ${$topSize === 'none' ? '' : `padding-top: ${theme.cuiSpacingSizes[$topSize]}px;`}
  ${$bottomSize === 'none' ? '' : `padding-bottom: ${theme.cuiSpacingSizes[$bottomSize]}px;`}
  `}
`

/**
 *
 * `<CuiPad>` adds padding to containers
 */
export const CuiPad: FunctionComponent<PropsWithChildren<Props>> = ({
  size = 'none',
  horizontalSize = size,
  leftSize = horizontalSize,
  rightSize = horizontalSize,
  verticalSize = size,
  topSize = verticalSize,
  bottomSize = verticalSize,
  children,
  ...baseProps
}) => (
  <CuiPadRoot
    {...baseProps}
    $leftSize={leftSize}
    $rightSize={rightSize}
    $topSize={topSize}
    $bottomSize={bottomSize}
  >
    {children}
  </CuiPadRoot>
)
