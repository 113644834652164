import { Outlet, ReactLocation, Router } from '@tanstack/react-location'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { HelmetProvider } from 'react-helmet-async'
import { routes } from 'src/routes'

import { ScrollToTopListener } from './ScrollToTopListener'
import { ThemeProvider } from './ThemeProvider'

const location = new ReactLocation()
const queryClient = new QueryClient({ defaultOptions: { queries: { retry: false } } })

export const AppRoot = () => {
  return (
    <HelmetProvider>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools />

          <Router location={location} routes={routes}>
            <ScrollToTopListener />

            <Outlet />
          </Router>
        </QueryClientProvider>
      </ThemeProvider>
    </HelmetProvider>
  )
}
