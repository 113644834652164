import { Fragment, type FunctionComponent, type PropsWithChildren, useRef, useState } from 'react'

import { CuiLink } from '../CuiLink'
import { CuiText } from '../CuiText'
import { useIsTextClamped } from './useIsTextClamped'

type Props = {
  /**
   *
   * Wraps to specified number of lines, showing ellipsis for overflow.
   *
   * @default 3
   */
  clamp?: number
}

export const CuiReadMore: FunctionComponent<PropsWithChildren<Props>> = ({ clamp = 3, children }) => {
  const ref = useRef<HTMLDivElement | null>(null)

  const [isShowingMore, setIsShowingMore] = useState(false)

  const isClamped = useIsTextClamped({ ref, text: isShowingMore ? null : children })

  return (
    <Fragment>
      <CuiText as='span' ref={ref} clamp={isShowingMore ? false : clamp} color='hushed'>
        {children}
      </CuiText>

      {(isClamped || isShowingMore) && (
        <Fragment>
          {' '}
          <CuiLink onClick={() => setIsShowingMore(!isShowingMore)}>
            {isShowingMore ? 'Show less' : 'Show more'}{' '}
          </CuiLink>
        </Fragment>
      )}
    </Fragment>
  )
}
