import _ from 'lodash'
import { forwardRef, type FunctionComponent, useMemo } from 'react'
import { type APISchemas } from 'src/api/api'
import { type Term } from 'src/api/schema'
import {
  CuiBadge,
  CuiDraggable,
  CuiDroppable,
  CuiFlexGroup,
  CuiIcon,
  CuiPad,
  CuiText,
} from 'src/cui/components'
import styled, { useTheme } from 'styled-components'

import type { TermCourses } from '.'
import { CoursePlan } from './CoursePlan'

const PlanQuarterWrapper = styled.div`
  overflow: hidden;
  background-color: ${({ theme }) => theme.cuiColors.background};
  box-shadow: 0px 0px 4px ${({ theme }) => theme.cuiColors.boxShadow};
  border-radius: 8px;
`

const CourseListWrapper = styled.div`
  width: 100%;
  min-height: 300px;
  max-height: 300px;
  overflow-y: scroll;
`

const CourseList = forwardRef<HTMLDivElement, any>(({ children }, ref) => (
  <CourseListWrapper ref={ref}>{children}</CourseListWrapper>
))

const CoursePlanWrapper = styled.div`
  margin-bottom: 12px;
`

type CourseListItemProps = {
  course: { id: string; meta_offering: APISchemas['MetaOffering'][]; term: Term }
  index: number
}

const CourseListItem: FunctionComponent<CourseListItemProps> = ({ index, course }) => {
  const cartaTheme = useTheme()

  return (
    <CuiDraggable draggableId={course.id} index={index}>
      {(provided) => (
        <CoursePlanWrapper
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <CoursePlan
            // @ts-ignore
            course={course.meta_offering}
            color={cartaTheme.cuiColors.darkAccent}
            isTaken={false}
            // onCheck={() => {}}
            onDelete={() => {}}
          />
        </CoursePlanWrapper>
      )}
    </CuiDraggable>
  )
}

type Props = {
  id: string
  termCourses: TermCourses
}

export const PlanQuarter: FunctionComponent<Props> = ({ id, termCourses }) => {
  const totalUnits = useMemo(() => getTotalUnits(termCourses.courses), [termCourses.courses])

  return (
    <PlanQuarterWrapper>
      <CuiPad size='m'>
        <CuiPad bottomSize='s'>
          <CuiFlexGroup justifyContent='spaceBetween'>
            <CuiText size='title4'>{termCourses.season}</CuiText>
            <CuiIcon type='ext-link' size='m' />
          </CuiFlexGroup>
        </CuiPad>
        <CuiFlexGroup direction='column' gutterSizeVertical='l'>
          <CuiDroppable droppableId={id}>
            {(provided) => (
              <CourseList ref={provided.innerRef} {...provided.droppableProps}>
                {termCourses.courses.map((course, index) => (
                  <CourseListItem key={course.id} index={index} course={course} />
                ))}
                {provided.placeholder}
              </CourseList>
            )}
          </CuiDroppable>
        </CuiFlexGroup>
        <CuiFlexGroup justifyContent='spaceBetween'>
          <CuiText size='paragraph2' color='hushed'>
            {totalUnits} units
          </CuiText>
          <CuiBadge>Current</CuiBadge>
        </CuiFlexGroup>
      </CuiPad>
    </PlanQuarterWrapper>
  )

  function getTotalUnits(
    courses: { id: string; meta_offering: APISchemas['MetaOffering'][]; term: any }[]
  ) {
    const unitsPerCourse = courses.map((course) => {
      return course.meta_offering.reduce(
        (acc, offering) => {
          acc.minimum += offering.units.minimum
          acc.maximum += offering.units.maximum

          return acc
        },
        { minimum: 0, maximum: 0 }
      )
    })

    const minimumUnits = _.sumBy(unitsPerCourse, 'minimum')
    const maximumUnits = _.sumBy(unitsPerCourse, 'maximum')

    if (minimumUnits === maximumUnits) {
      return minimumUnits
    }

    return `${minimumUnits} - ${maximumUnits}`
  }
}

// const { minimumUnits, maximumUnits } = courses.reduce(
//   (accumulator: { minimumUnits: number; maximumUnits: number }, currCourse: any) => {
//     const { minimum: minimumUnits, maximum: maximumUnits } = currCourse.metaOffering.units
//     accumulator.minimumUnits += minimumUnits
//     accumulator.maximumUnits += maximumUnits

//     return accumulator
//   },
//   { minimumUnits: 0, maximumUnits: 0 }
// )
