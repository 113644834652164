import * as RadixCheckbox from '@radix-ui/react-checkbox'
import * as RadixLabel from '@radix-ui/react-label'
import classnames from 'classnames'
import { type FunctionComponent, type PropsWithChildren, useMemo } from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import { CuiFlexGroup } from '../CuiFlexGroup'
import { CuiIcon } from '../CuiIcon'
import { CuiPad } from '../CuiPad'
import { CuiText } from '../CuiText'

/**
 *
 * Props for <CuiCheckbox>.
 */
type Props = {
  /**
   *
   * The state of the checkbox
   */
  checked: boolean

  /**
   *
   * When `true`, prevents the user from interacting with the checkbox
   *
   * @default false
   */
  disabled?: boolean

  /**
   *
   * The label
   */
  label?: string

  /**
   *
   * The function called when the checkbox is clicked
   */
  onChange: (checked: boolean) => void

  /**
   *
   * This component cannot have `children`.
   */
  children?: never
}

const CuiCheckboxRoot = styled(RadixCheckbox.Root)`
  all: unset;
  cursor: pointer;
  background-color: ${({ theme }) => theme.cuiColors.background};
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.cuiColors.darkestShade};

  &.CuiCheckboxRoot--checked {
    background-color: ${({ theme }) => theme.cuiColors.accent};
    border-color: ${({ theme }) => theme.cuiColors.accent};
    color: ${({ theme }) => theme.cuiColors.inverseText};
  }

  &.CuiCheckboxRoot--disabled {
    cursor: not-allowed;
    border-color: ${({ theme }) => theme.cuiColors.darkShade};

    &.CuiCheckboxRoot--checked {
      background-color: ${({ theme }) => theme.cuiColors.darkShade};
    }
  }
`

const CuiCheckboxIndicator = styled(RadixCheckbox.Indicator)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const CuiCheckboxLabelRoot = styled(RadixLabel.Root)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.cuiColors.text};

  & label {
    cursor: pointer;
  }

  &.CuiCheckboxLabelRoot--disabled {
    color: ${({ theme }) => theme.cuiColors.darkShade};

    & label {
      cursor: not-allowed;
    }
  }
`

/**
 *
 * `<CuiCheckbox>`
 */
export const CuiCheckbox: FunctionComponent<PropsWithChildren<Props>> = ({
  checked,
  label,
  disabled = false,
  onChange,
}) => {
  const inputId = useMemo(() => `CuiCheckbox-${uuid()}`, [])

  const checkbox = (
    <CuiCheckboxRoot
      id={inputId}
      checked={checked}
      disabled={disabled}
      onCheckedChange={onChange}
      className={classnames({
        'CuiCheckboxRoot--checked': checked === true,
        'CuiCheckboxRoot--disabled': disabled === true,
      })}
    >
      <CuiCheckboxIndicator>
        <CuiIcon type='check' size='s' />
      </CuiCheckboxIndicator>
    </CuiCheckboxRoot>
  )

  if (!label) {
    return checkbox
  }

  return (
    <CuiFlexGroup>
      <div>{checkbox}</div>
      <CuiPad leftSize='xs'>
        <CuiCheckboxLabelRoot
          htmlFor={inputId}
          className={classnames({
            'CuiCheckboxLabelRoot--disabled': disabled === true,
          })}
        >
          <CuiText as='label' color='inherit'>
            {label}
          </CuiText>
        </CuiCheckboxLabelRoot>
      </CuiPad>
    </CuiFlexGroup>
  )
}
