export function truncateMiddle(str: string, maxChars: number) {
  if (str.length <= maxChars) {
    return str
  }

  const separator = '...'
  const sepLen = separator.length
  const charsToShow = maxChars - sepLen
  const frontChars = Math.ceil(charsToShow / 2)
  const backChars = Math.floor(charsToShow / 2)

  return str.slice(0, frontChars) + separator + str.slice(str.length - backChars)
}
