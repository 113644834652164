import { Fragment, type FunctionComponent, type PropsWithChildren, useEffect, useState } from 'react'
import { type APISchemas } from 'src/api/api'
import { useInfiniteReviews } from 'src/api/reviews'
import { CuiIcon, CuiInfiniteLoader, CuiInput, CuiSpacer, CuiText } from 'src/cui/components'
import styled from 'styled-components'

const ReviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* padding-right: 32px;
  padding-left: 32px; */
`

const ReviewContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  gap: 16px;
`
const CourseInfoBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const CourseInfoSectionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 60px;
  align-items: center;
  width: 100%;
`

const CourseInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  gap: 4px;
`

const ReviewStarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
`

type Props = {
  /**
   *
   * The course being displayed
   *
   */
  review: APISchemas['CourseReview']
}

const CourseReview: FunctionComponent<PropsWithChildren<Props>> = ({ review }) => {
  const rating = review.overallInstructionQuality
  const quarter = review.season
  const { year } = review

  const reviewText = review.text

  return (
    <ReviewWrapper>
      {/* <ReviewBorder /> */}
      <ReviewContentWrapper>
        <CourseInfoBarWrapper>
          <CourseInfoSectionsWrapper>
            <CourseInfoWrapper>
              <CuiText size='title3'>Rating</CuiText>
              <ReviewStarWrapper>
                {rating && <CuiIcon type='star-filled' color='accent' />}
                <CuiText size='paragraph2'>{rating ?? 'No Rating'}</CuiText>
              </ReviewStarWrapper>
            </CourseInfoWrapper>
            <CourseInfoWrapper>
              <CuiText size='title3'>Instructor</CuiText>
              <CuiText size='paragraph2'>Nick Parlante</CuiText>
            </CourseInfoWrapper>
            <CourseInfoWrapper>
              <CuiText size='title3'>Quarter Taken</CuiText>
              <CuiText size='paragraph2'>
                {quarter} {year}
              </CuiText>
            </CourseInfoWrapper>
          </CourseInfoSectionsWrapper>
        </CourseInfoBarWrapper>
        <CuiText size='paragraph1'>{reviewText}</CuiText>
      </ReviewContentWrapper>
      {/* <ReviewBorder /> */}
    </ReviewWrapper>
  )
}

type CourseReviewsProps = {
  /**
   *
   * The course being displayed
   *
   */
  course: APISchemas['MetaOffering']
}

export const CourseReviews: FunctionComponent<PropsWithChildren<CourseReviewsProps>> = ({ course }) => {
  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteReviews({
    year: 2025,
    id: course.id,
  })

  const [searchValue, setSearchValue] = useState<string>('')
  const [filteredReviews, setFilteredReviews] = useState<APISchemas['CourseReview'][]>([])

  const onChange = (value: string) => {
    setSearchValue(value)
  }

  useEffect(() => {
    const filtered = data?.reviews
      ? data.reviews.filter((review) => {
          return review?.text?.toLowerCase().includes(searchValue.toLowerCase())
        })
      : data?.reviews

    setFilteredReviews(filtered ?? [])
  }, [searchValue, data?.reviews])

  return (
    <Fragment>
      <CuiInput value={searchValue} onChange={onChange} placeholder='Search reviews' iconType='search' />
      {filteredReviews.map((review, idx) => (
        <Fragment key={idx}>
          {idx > 0 && <CuiSpacer as='hr' size='none' />}

          <CourseReview review={review} />
        </Fragment>
      ))}

      <CuiInfiniteLoader
        hasNextPage={hasNextPage}
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        // loader={<LoadingCourses hasTopBorder={isFetchingNextPage} />}
        loader={<div>Loading...</div>}
        fetchNextPage={fetchNextPage}
      />
    </Fragment>
  )
}
