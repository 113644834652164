import { type FunctionComponent, type PropsWithChildren } from 'react'
import { type CuiCalendarDataProps } from 'src/routes/Home/Sidebar'
import styled from 'styled-components'

import { CuiFlexGroup } from '../CuiFlexGroup'
import { CuiText } from '../CuiText'

const Cols = [' ', 'Mo', 'Tu', 'We', 'Th', 'Fr']

type CuiCalendarProps<TCalendarData> = {
  /**
   *
   * course data
   *
   *
   */
  data: TCalendarData[]

  /**
   *
   * get title for course
   *
   *
   */
  getCourseTitle: (data: TCalendarData) => string

  /**
   *
   * get start time for course
   *
   *
   */
  getCourseStart: (props: CuiCalendarDataProps) => number

  /**
   *
   * get duration for course
   *
   *
   */
  getCourseDuration: (props: CuiCalendarDataProps) => number

  /**
   *
   * get day of week for course
   *
   *
   */
  getCourseDay: (props: CuiCalendarDataProps) => number[]

  /**
   *
   * current quarter
   *
   *
   */
  currentQuarter: string

  /**
   *
   * end year
   *
   *
   */
  endYear: number

  /**
   *
   * width of each column
   *
   * @default 48
   */
  width?: number

  /**
   *
   * height of each column
   *
   * @default 38
   */
  height: number

  /**
   *
   * number of rows in grid
   *
   *  @default 12
   */
  rows?: number

  /**
   *
   * column titles
   *
   *  @default Cols
   */
  cols?: string[]

  /**
   *
   * list of planned courses
   *
   *
   */
  courses?: string[]
}

type CuiCalendarCourseProps = {
  /**
   *
   * width of each column
   *
   * @default 48
   */
  width?: number

  /**
   *
   * height of each column
   *
   * @default 38
   */
  height: number

  /**
   *
   * course title
   *
   *
   */
  courseTitle: string

  /**
   *
   * course duration
   *
   *
   */
  courseDuration: number
}

const Cell = styled.div<{ width?: number; height?: number; border?: string }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  border: ${({ border }) => border};
  border-color: ${({ theme }) => theme.cuiColors.darkAccent};
  box-sizing: border-box;
  margin-left: -1px;
  margin-bottom: -1px;
  text-align: center;
`

const Header = styled.div<{ width?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width}px;
  margin-bottom: 10px;
`

const Course = styled.div<{ width?: number; height?: number }>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  background-color: ${({ theme }) => theme.cuiColors.darkAccent};
  color: ${({ theme }) => theme.cuiColors.textOnDarkBackground};
  padding: 2px;
  margin-left: -1px;
  margin-bottom: -1px;
  z-index: 0;
  font-size: 12px;
  line-height: 12px;
`

const startHour = 8

const CuiCalendarCourse: FunctionComponent<PropsWithChildren<CuiCalendarCourseProps>> = ({
  width,
  height,
  courseTitle,
  courseDuration,
}) => {
  const courseTime = Math.max(height / 2, courseDuration * height)

  return (
    <Course width={width} height={courseTime}>
      {courseTitle}
    </Course>
  )
}

export const CuiCalendar: FunctionComponent<CuiCalendarProps<any>> = ({
  data,
  getCourseTitle,
  getCourseStart,
  getCourseDuration,
  getCourseDay,
  width,
  height,
  rows,
  cols = Cols,
  currentQuarter,
  endYear,
}) => {
  return (
    <CuiFlexGroup direction='column' alignItems='center'>
      <CuiFlexGroup>
        {cols.map((col, index) => (
          <Header key={index} width={width}>
            <CuiText>{col}</CuiText>
          </Header>
        ))}
      </CuiFlexGroup>
      {[...Array(rows)].map((_, rowIndex) => (
        <CuiFlexGroup key={rowIndex}>
          {cols.map((col, colIndex) => (
            <Cell
              key={`${rowIndex}-${colIndex}`}
              width={width}
              height={height}
              border={colIndex === 0 ? 'none' : '1px solid'}
            >
              {data.map((course) => {
                const currentDays = getCourseDay({ courseId: course.id, currentQuarter, endYear })
                const currentTime = Math.floor(
                  getCourseStart({ courseId: course.id, currentQuarter, endYear })
                )
                const courseDuration = getCourseDuration({
                  courseId: course.id,
                  currentQuarter,
                  endYear,
                })
                const courseTitle = getCourseTitle(course.courseCode)

                if (currentDays.includes(colIndex) && rowIndex === currentTime) {
                  return (
                    <CuiCalendarCourse
                      key={course.id}
                      width={width}
                      height={height}
                      courseTitle={courseTitle}
                      courseDuration={courseDuration}
                    />
                  )
                }

                return null
              })}
              {colIndex === 0 && rowIndex % 3 === 0 ? (
                <CuiText key={`time-${rowIndex}`} size='caption'>
                  {(startHour + rowIndex) % 12 || 12} {startHour + rowIndex >= 12 ? 'pm' : 'am'} &nbsp;
                </CuiText>
              ) : null}
            </Cell>
          ))}
        </CuiFlexGroup>
      ))}
    </CuiFlexGroup>
  )
}
