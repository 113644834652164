import ky from 'ky'

import { type components, type paths } from './openapi-schema'

const backendUrl = process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:5000'

export const api = ky.create({ prefixUrl: backendUrl, credentials: 'include' })

export type APISchemas = components['schemas']

export type APIRoutes = paths
