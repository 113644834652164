import { rgba } from 'polished'

import { base } from './base'
import { palette } from './palette'
import { type CuiTheme, type CuiThemeColors, type CuiThemeVizColors } from './types'

const cuiColors: CuiThemeColors = {
  text: palette.neutral90,
  lighterText: palette.neutral70,
  lightestText: palette.neutral50,
  textOnDarkBackground: palette.white,
  inverseText: palette.white,

  accent: palette.brandInteractive60,
  darkAccent: palette.brandMain70,
  lightAccent: palette.brandInteractive0,

  lightConstructive: palette.success0,
  constructive: palette.success60,

  lightDestructive: palette.error0,
  destructive: palette.error50,

  lightWarning: palette.warning0,
  warning: palette.warning50,

  background: palette.white,

  lightestShade: palette.white,
  lighterShade: palette.neutral0,
  lightShade: palette.neutral10,
  darkShade: palette.neutral20,
  darkerShade: palette.neutral60,
  darkestShade: palette.neutral80,

  disabled01: palette.neutral10,
  disabled02: palette.neutral30,

  overlayHover01: rgba(palette.black, 0.04),
  overlayHover02: rgba(palette.white, 0.1),
  overlayPressed01: rgba(palette.black, 0.12),
  overlayPressed02: rgba(palette.white, 0.32),

  overlayAccent: rgba(palette.brandInteractive60, 0.1),
  overlayAccentHover: rgba(palette.brandInteractive60, 0.15),
  overlayAccentPressed: rgba(palette.brandInteractive60, 0.25),

  skeletonBackground: palette.neutral0,
  skeletonHighlight: palette.neutral10,

  boxShadow: palette.black15,
}

const cuiVizColors: CuiThemeVizColors = {
  dataViz01: palette.brandLight60,
  dataViz02: palette.accentPrimary70,
  dataViz03: palette.accentBlue50,
  dataViz04: palette.accentOrange30,
  dataViz05: palette.accentPurple70,
  dataViz06: palette.success20,
  dataViz07: palette.brandLight20,
}

export const cuiLightTheme: CuiTheme = {
  ...base,
  name: 'cui-light',
  cuiColors,
  cuiVizColors,
}
