// import { delay } from 'lodash'
import { Fragment, type FunctionComponent, useState } from 'react'
import { type APISchemas } from 'src/api/api'
import { useCourseSchedules } from 'src/api/course'
import { type InstructionMode } from 'src/api/schema'
import {
  CuiFlexGroup,
  CuiIcon,
  CuiLoadingSkeleton,
  CuiReadMore,
  CuiSpacer,
  CuiTabs,
  CuiText,
} from 'src/cui/components'
import styled from 'styled-components'

type CourseProps = { course: APISchemas['MetaOffering'] }

type LecturesTab = 'aut' | 'win' | 'spr' | 'sum'

type TextProps = {
  schedule: APISchemas['Schedules']
  title: string
}

type ScheduleProps = { schedule: APISchemas['Schedules'] }

const ScheduleDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`

// Add padding between elements
const SectionDiv = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.cuiColors.skeletonHighlight};
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;

  & > *:not(:last-child) {
    padding-bottom: 20px;
  }
`

const SectionColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  flex: 1;
`

const SectionColumnWide = styled(SectionColumn)`
  flex: 2;
`

const RatingDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40px;
  gap: 4px;
`

const EnrollmentDiv = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.cuiColors.lightAccent};
  padding: 16px;
`

const ScheduleText: FunctionComponent<TextProps> = ({ schedule, title }) => {
  return (
    <CuiFlexGroup direction='column' alignItems='flexStart'>
      <CuiText size='title3'>{title}</CuiText>
      <CuiText size='paragraph1' color='hushed'>
        {title === 'Attendance' && attendanceBeautify(schedule.section.instructionMode)}
        {title === 'Location' && (schedule.location ?? 'Unknown')}
      </CuiText>
      {title === 'Notes' && <CuiReadMore>{schedule.section.notes ?? 'No notes provided'}</CuiReadMore>}
      {title === 'Instructor(s)' && (
        <CuiFlexGroup direction='row' gutterSize='m' alignItems='flexStart'>
          <CuiFlexGroup direction='column' gutterSize='xs' alignItems='flexStart'>
            {schedule.instructors.map((instructor) => (
              <CuiText size='paragraph1' key={instructor.id}>
                {instructor.instructor.preferredName[0]}.{' '}
                {instructor.instructor.preferredName.split(' ')[1]}
              </CuiText>
            ))}
          </CuiFlexGroup>
          <RatingDiv>
            {schedule.instructors.map((instructor) => (
              <CuiFlexGroup direction='row' gutterSize='xs' key={instructor.id}>
                <CuiIcon type='star-filled' color='accent' />
                <CuiText size='paragraph1'>—</CuiText>
              </CuiFlexGroup>
            ))}
          </RatingDiv>
        </CuiFlexGroup>
      )}
    </CuiFlexGroup>
  )
}

const ScheduleDisplay: FunctionComponent<ScheduleProps> = ({ schedule }) => {
  return (
    <CuiFlexGroup direction='row' gutterSize='xs' alignItems='flexStart'>
      <CuiText size='paragraph1'>
        {schedule.meetingDays.map((day) => day.substring(0, 3)).join(', ')} ·{' '}
        {hrAndMinutesToLocaleTime(
          schedule.meetingTime.lowerBound.hour,
          schedule.meetingTime.lowerBound.minute
        )}{' '}
        -{' '}
        {hrAndMinutesToLocaleTime(
          schedule.meetingTime.upperBound.hour,
          schedule.meetingTime.upperBound.minute
        )}
      </CuiText>
    </CuiFlexGroup>
  )
}

const SectionDisplay: FunctionComponent<ScheduleProps> = ({ schedule }) => {
  return (
    <CuiFlexGroup direction='column' gutterSize='xs' alignItems='flexStart'>
      <CuiText size='title2'>
        {schedule.section.component.substring(0, 3).toUpperCase()}{' '}
        {schedule.section.catalogSectionNumber}
      </CuiText>
      <CuiText size='paragraph1'>
        {schedule.section.component}, Section {schedule.section.catalogSectionNumber}
      </CuiText>
    </CuiFlexGroup>
  )
}

export const CourseDetailedSchedule: FunctionComponent<CourseProps> = ({ course }) => {
  const schedule = useCourseSchedules({ id: course.id })

  const [activeTab, setActiveTab] = useState<LecturesTab>(
    course.seasons.map((season) => termCompress(season))[0]
  )

  // Loading state, show skeleton
  if (schedule.isLoading) {
    return (
      <CuiFlexGroup direction='column' alignItems='flexStart' gutterSizeVertical='xs'>
        <CuiText size='title3'>Instructors</CuiText>

        <CuiSpacer size='xs' />
        <CuiLoadingSkeleton height={100} />
        <CuiSpacer size='s' />

        <CuiText size='title3'>Lectures and Sections</CuiText>
        <CuiLoadingSkeleton height={50} />
        <CuiLoadingSkeleton height={200} count={2} />
      </CuiFlexGroup>
    )
  }

  // Error state
  if (schedule.isError) {
    return (
      <CuiFlexGroup direction='column' alignItems='flexStart' gutterSizeVertical='xs'>
        <CuiText size='title3'>Instructors</CuiText>
        <CuiText>We couldn't load the instructors — please try again later.</CuiText>
        <CuiSpacer size='xs' />
        <CuiText size='title3'>Lectures and Sections</CuiText>
        <CuiText>We couldn't load the instructors — please try again later.</CuiText>
      </CuiFlexGroup>
    )
  }

  const tabs = getTabs(course.seasons.map((season) => termCompress(season)))

  const scheduleData = schedule.data?.entries as unknown as APISchemas['Schedules'][]

  const scheduleForTerm = scheduleData.filter(
    (schedule) => schedule.term.season === termBeautify(activeTab)
  )

  // Process intsrucor data into a list of instructors by term.

  const instructorList = {
    Autumn: [] as Array<APISchemas['Instructor']>,
    Winter: [] as Array<APISchemas['Instructor']>,
    Spring: [] as Array<APISchemas['Instructor']>,
    Summer: [] as Array<APISchemas['Instructor']>,
  }

  scheduleData.forEach((schedule) => {
    instructorList[schedule.term.season].push(
      ...schedule.instructors.map((instructor) => instructor.instructor)
    )
  })

  // Order with Lectures first, then Sections, show the ones with location first
  scheduleForTerm.sort((a, b) => {
    if (a.section.component === 'Lecture' && b.section.component !== 'Lecture') {
      return -1
    }

    if (a.section.component !== 'Lecture' && b.section.component === 'Lecture') {
      return 1
    }

    if (a.location && !b.location) {
      return -1
    }

    if (!a.location && b.location) {
      return 1
    }

    return 0
  })

  return (
    <CuiFlexGroup direction='column' alignItems='flexStart' gutterSizeVertical='s'>
      <CuiText size='title3'>Instructors</CuiText>
      <CuiSpacer size='xs' />
      <ScheduleDiv>
        {Object.keys(instructorList).map((term) => (
          <SectionColumn key={term}>
            <CuiText size='title3'>{term}</CuiText>
            <CuiFlexGroup direction='column' gutterSize='xs' alignItems='flexStart'>
              {instructorList[term as keyof typeof instructorList].slice(0, 3).map((instructor) => (
                <CuiText size='paragraph1' key={instructor.id} clamp={1}>
                  {instructor.preferredName}
                </CuiText>
              ))}
              {instructorList[term as keyof typeof instructorList].length > 3 && (
                <CuiText size='paragraph1'>
                  +{instructorList[term as keyof typeof instructorList].length - 3} more
                </CuiText>
              )}
              {instructorList[term as keyof typeof instructorList].length === 0 && (
                <CuiText size='paragraph1'>--</CuiText>
              )}
            </CuiFlexGroup>
          </SectionColumn>
        ))}
      </ScheduleDiv>
      <CuiSpacer size='s' />

      <CuiText size='title3'>Lectures and Sections</CuiText>
      <CuiTabs items={tabs} />
      {scheduleForTerm.map((schedule) => (
        <Fragment key={schedule.id}>
          <SectionDiv>
            <CuiFlexGroup direction='row' gutterSize='xl' justifyContent='flexStart'>
              <SectionDisplay schedule={schedule} />
              <EnrollmentDiv>
                <CuiText size='paragraph2'>297/300 enrolled</CuiText>
              </EnrollmentDiv>
              <ScheduleDisplay schedule={schedule} />
            </CuiFlexGroup>
            <CuiFlexGroup direction='row' gutterSize='xl' alignItems='flexStart'>
              <SectionColumn>
                <ScheduleText schedule={schedule} title='Instructor(s)' />
              </SectionColumn>
              <SectionColumn>
                <CuiFlexGroup direction='column' gutterSize='xs' alignItems='flexStart'>
                  <ScheduleText schedule={schedule} title='Attendance' />
                  <CuiSpacer size='xs' />
                  <ScheduleText schedule={schedule} title='Location' />
                </CuiFlexGroup>
              </SectionColumn>
              <SectionColumnWide>
                <ScheduleText schedule={schedule} title='Notes' />
              </SectionColumnWide>
            </CuiFlexGroup>
          </SectionDiv>
        </Fragment>
      ))}
    </CuiFlexGroup>
  )

  function getTabs(
    tabs: Array<LecturesTab> = ['aut', 'win', 'spr', 'sum']
  ): Array<{ label: string; active: boolean; onClick: () => void }> {
    return tabs.map((tab) => ({
      label: termBeautify(tab),
      active: activeTab === tab,
      onClick: () => setActiveTab(tab),
    }))
  }
}

function termBeautify(term: LecturesTab) {
  switch (term) {
    case 'aut':
      return 'Autumn'
    case 'win':
      return 'Winter'
    case 'spr':
      return 'Spring'
    case 'sum':
      return 'Summer'
  }
}

function attendanceBeautify(attendance: InstructionMode) {
  switch (attendance) {
    case 'InPerson':
      return 'In Person'
    case 'IndependentStudies':
      return 'Independent Studies'
    case 'OnlineAsynchronous':
      return 'Online · Asynchronous'
    case 'OnlineSynchronous':
      return 'Online · Synchronous'
    case 'RemoteAsynchronous':
      return 'Remote · Asynchronous'
    case 'RemoteSynchronous':
      return 'Remote · Synchronous'
    case 'WorldWideWeb':
      return 'World Wide Web'
    default:
      return attendance.toString()
  }
}

function termCompress(term: string): LecturesTab {
  switch (term) {
    case 'Autumn':
      return 'aut'
    case 'Winter':
      return 'win'
    case 'Spring':
      return 'spr'
    case 'Summer':
      return 'sum'
    default:
      throw new Error('Invalid term')
  }
}

function hrAndMinutesToLocaleTime(hour: number, minutes: number) {
  return new Date(0, 0, 0, hour, minutes).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })
}
