import { Pie as NivoPie } from '@nivo/pie'
import { Fragment, type FunctionComponent, type PropsWithChildren, useMemo } from 'react'
import { type CuiThemeVizColor } from 'src/cui/themes/types'
import { useIsMobile } from 'src/utils/mediaQuery'
import { useTheme } from 'styled-components'

import { ResponsiveWrapper } from '../../internal'

export type DataPoint = {
  /**
   *
   * id of slice
   */
  id: string

  /**
   *
   * Label of slice
   */
  label: string

  /**
   *
   * Percentage value
   */
  value: number

  /**
   *
   * Color of slice
   */
  color: CuiThemeVizColor
}

type Props = {
  /**
   *
   * Height of graph
   */
  height: number

  /**
   *
   * Width of graph
   */
  width: number

  /**
   *
   * Data to display
   */
  data: DataPoint[]
}

const Pie: FunctionComponent<PropsWithChildren<Props>> = ({ height, width, data }) => {
  const isMobile = useIsMobile()
  const cartaTheme = useTheme()
  const theme = {
    grid: {
      line: {
        stroke: cartaTheme.cuiColors.text,
      },
    },
  }

  const transformedData = useMemo(
    () => data.map((datum) => ({ ...datum, color: cartaTheme.cuiVizColors[datum.color] })),
    [data, cartaTheme]
  )

  return (
    <NivoPie
      colors={{ datum: 'data.color' }}
      data={transformedData}
      enableArcLinkLabels={!isMobile}
      enableArcLabels={false}
      height={height}
      innerRadius={0.5}
      margin={{ top: 0, right: 80, bottom: 0, left: 80 }}
      padAngle={2}
      arcLinkLabel={(dataPoint: any) =>
        (
          <Fragment>
            <tspan dy='-0.6em' fontFamily='Source Sans Pro' fontSize='10px' x='0'>
              {dataPoint.label}
            </tspan>
            <tspan dy='1.2em' fontFamily='Source Sans Pro' fontSize='10px' fontWeight='bold' x='0'>
              {`${dataPoint.value}%`}
            </tspan>
          </Fragment>
        ) as any
      }
      arcLinkLabelsColor={{ from: 'color', modifiers: [] }}
      arcLinkLabelsDiagonalLength={20}
      arcLinkLabelsStraightLength={4}
      arcLinkLabelsThickness={2}
      arcLinkLabelsTextColor={{ theme: 'grid.line.stroke' }}
      arcLinkLabelsTextOffset={6}
      arcLabelsSkipAngle={10}
      sortByValue={true}
      startAngle={1}
      theme={theme}
      width={width}
      isInteractive={false}
    />
  )
}

export const CuiPieChart: FunctionComponent<
  PropsWithChildren<{ data: DataPoint[]; children?: never }>
> = ({ data }) => {
  return (
    <ResponsiveWrapper>
      {(width: number, height: number) => <Pie data={data} height={height} width={width} />}
    </ResponsiveWrapper>
  )
}
