import { type DefaultGenerics, Navigate, type Route } from '@tanstack/react-location'
import { CuiFlexGroup } from 'src/cui/components'
import { CuiThemeProvider } from 'src/cui/CuiThemeProvider'

import { DashboardContainer } from './DashboardContainer'
import { Home } from './Home'
import { Sidebar } from './Home/Sidebar'
import { LandingPage } from './LandingPage'
import { MajorPlanner } from './MajorPlanner'
import { MajorPlannerSidebar } from './MajorPlanner/MajorPlannerSidebar'
import { Planner } from './Planner'

const staticRoutes: Route<DefaultGenerics>[] = [
  {
    path: '/landing',
    element: (
      // force light theme for static pages
      <CuiThemeProvider theme='cui-light'>
        <LandingPage />
      </CuiThemeProvider>
    ),
  },
  {
    path: '/about',
    element: 'About page!!',
  },
  {
    path: '/apply',
    element: 'Apply page!!',
  },
]

const authenticatedRoutes: Route<DefaultGenerics>[] = [
  {
    path: '/planner',
    element: (
      <CuiFlexGroup>
        <Sidebar />
        <DashboardContainer>
          <Planner.Planner />
        </DashboardContainer>
      </CuiFlexGroup>
    ),
  },
  {
    path: '/major-planner',
    element: (
      <CuiFlexGroup>
        <MajorPlannerSidebar />
        <DashboardContainer>
          <MajorPlanner.MajorPlanner />
        </DashboardContainer>
      </CuiFlexGroup>
    ),
  },
  {
    path: '/',
    element: (
      <CuiFlexGroup>
        <Sidebar />
        <DashboardContainer>
          <Home.Home />
        </DashboardContainer>
      </CuiFlexGroup>
    ),
    children: [
      {
        path: ':courseId',
        element: <Home.CourseDetail />,
        // Tab routing is handled within CourseDetail
        children: [
          {
            path: '/overview',
          },
          {
            path: '/schedule',
          },
          {
            path: '/data',
          },
          {
            path: '/reviews',
          },
        ],
      },
    ],
  },
]

export const routes = [...staticRoutes, ...wrapAuthenticatedRoutes(authenticatedRoutes)]

function wrapAuthenticatedRoutes(routes: Route<DefaultGenerics>[]): Route<DefaultGenerics>[] {
  return routes.map(wrapAuthenticatedRoute)
}

function wrapAuthenticatedRoute(route: Route<DefaultGenerics>): Route<DefaultGenerics> {
  const isAuthenticated = true

  return {
    ...route,
    loader: isAuthenticated ? route.loader : undefined,
    element: isAuthenticated ? route.element : <Navigate to='/landing' />,
    children: route.children ? wrapAuthenticatedRoutes(route.children) : undefined,
  }
}
