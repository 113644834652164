import { createContext, useState } from 'react'
import {
  DragDropContext,
  type DragDropContextProps as ReactBeautifulDragDropContextProps,
  type DraggableLocation,
  type DragStart,
  type DropResult,
  type ResponderProvided,
} from 'react-beautiful-dnd'

// Docs: github.com/atlassian/react-beautiful-dnd/blob/master/docs/api/drag-drop-context.md

export type CuiDropResult = DropResult & {
  destination: DraggableLocation
}

type Props = Pick<
  ReactBeautifulDragDropContextProps,
  /**
   *
   * type: React.ReactNode | null
   */
  | 'children'
  /**
   * callback for when the dragging starts
   *
   * (start, provided) => void | undefined
   */
  | 'onDragStart'
  /**
   * callback for when whenever something changes during a drag. Possible changes are:
   * - The position of the <Draggable /> has changed
   * - The <Draggable /> is now over a different <Droppable />
   * - The <Draggable /> is now over no <Droppable />
   *
   * (update, provided) => void
   */
  | 'onDragUpdate'
> & {
  /**
   * callback for after the dragging ends
   *
   * (result, provided) => void
   */
  onDragEnd: (result: CuiDropResult, provided: ResponderProvided) => void
}

export const CuiDragDropDataContext = createContext({ currDraggedId: '' })

export const CuiDragDropContext = ({
  children,
  onDragEnd: consumerOnDragEnd,
  onDragStart: consumerOnDragStart,
  onDragUpdate,
  ...restProps
}: Props) => {
  const [currDraggedId, setCurrDraggedId] = useState<string>('')

  return (
    <CuiDragDropDataContext.Provider value={{ currDraggedId }}>
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        onDragUpdate={onDragUpdate}
        {...restProps}
      >
        {children}
      </DragDropContext>
    </CuiDragDropDataContext.Provider>
  )

  function onDragStart(start: DragStart, provided: ResponderProvided) {
    setCurrDraggedId(start.draggableId)

    consumerOnDragStart?.(start, provided)
  }

  function onDragEnd(result: DropResult, provided: ResponderProvided) {
    setCurrDraggedId('')

    const { destination, source } = result

    // No destination
    if (!destination) {
      return
    }

    // Item didn't move
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    consumerOnDragEnd(result as CuiDropResult, provided)
  }
}
