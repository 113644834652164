import { ReactComponent as AddIcon } from './vectors/add.svg'
import { ReactComponent as AlertIcon } from './vectors/alert.svg'
import { ReactComponent as ArrowBackIcon } from './vectors/arrow-back.svg'
import { ReactComponent as ArrowForwardIcon } from './vectors/arrow-forward.svg'
import { ReactComponent as BookIcon } from './vectors/book.svg'
import { ReactComponent as BookmarkIcon } from './vectors/bookmark.svg'
import { ReactComponent as BuildingProjectIcon } from './vectors/building-project.svg'
import { ReactComponent as CalendarIcon } from './vectors/calendar.svg'
import { ReactComponent as CaretDownIcon } from './vectors/caret-down.svg'
import { ReactComponent as CaretLeftIcon } from './vectors/caret-left.svg'
import { ReactComponent as CaretRightIcon } from './vectors/caret-right.svg'
import { ReactComponent as CaretUpIcon } from './vectors/caret-up.svg'
import { ReactComponent as CheckIcon } from './vectors/check.svg'
import { ReactComponent as ChevronDownIcon } from './vectors/chevron-down.svg'
import { ReactComponent as ChevronLeftIcon } from './vectors/chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from './vectors/chevron-right.svg'
import { ReactComponent as ChevronUpIcon } from './vectors/chevron-up.svg'
import { ReactComponent as CloseIcon } from './vectors/close.svg'
import { ReactComponent as CodingIcon } from './vectors/coding.svg'
import { ReactComponent as CreativeProjectIcon } from './vectors/creative-project.svg'
import { ReactComponent as EmailIcon } from './vectors/email.svg'
import { ReactComponent as EmptyIcon } from './vectors/empty.svg'
import { ReactComponent as EssaysIcon } from './vectors/essays.svg'
import { ReactComponent as ExamIcon } from './vectors/exam.svg'
import { ReactComponent as ExtLinkIcon } from './vectors/ext-link.svg'
import { ReactComponent as FileIcon } from './vectors/file.svg'
import { ReactComponent as FitnessIcon } from './vectors/fitness.svg'
import { ReactComponent as GroupProjectsIcon } from './vectors/group-projects.svg'
import { ReactComponent as HappyIcon } from './vectors/happy.svg'
import { ReactComponent as HeartIcon } from './vectors/heart.svg'
import { ReactComponent as InfoIcon } from './vectors/info.svg'
import { ReactComponent as LabsIcon } from './vectors/labs.svg'
import { ReactComponent as MenuIcon } from './vectors/menu.svg'
import { ReactComponent as NeutralIcon } from './vectors/neutral.svg'
import { ReactComponent as OverflowHorizontalIcon } from './vectors/overflow-horizontal.svg'
import { ReactComponent as OverflowVerticalIcon } from './vectors/overflow-vertical.svg'
import { ReactComponent as PresentationsIcon } from './vectors/presentations.svg'
import { ReactComponent as PsetsIcon } from './vectors/psets.svg'
import { ReactComponent as RadioEmptyIcon } from './vectors/radio-empty.svg'
import { ReactComponent as RadioFilledIcon } from './vectors/radio-filled.svg'
import { ReactComponent as RecentIcon } from './vectors/recent.svg'
import { ReactComponent as ResearchIcon } from './vectors/research.svg'
import { ReactComponent as SadIcon } from './vectors/sad.svg'
import { ReactComponent as SearchIcon } from './vectors/search.svg'
import { ReactComponent as ShareIcon } from './vectors/share.svg'
import { ReactComponent as StarEmptyIcon } from './vectors/star-empty.svg'
import { ReactComponent as StarFilledIcon } from './vectors/star-filled.svg'
import { ReactComponent as StarHalfIcon } from './vectors/star-half.svg'
import { ReactComponent as ThumbDownFilledIcon } from './vectors/thumb-down-filled.svg'
import { ReactComponent as ThumbDownOutlineIcon } from './vectors/thumb-down-outline.svg'
import { ReactComponent as ThumbUpFilledIcon } from './vectors/thumb-up-filled.svg'
import { ReactComponent as ThumbUpOutlineIcon } from './vectors/thumb-up-outline.svg'
import { ReactComponent as TrashIcon } from './vectors/trash.svg'
import { ReactComponent as WebsiteIcon } from './vectors/website.svg'
import { ReactComponent as WriteIcon } from './vectors/write.svg'

/**
 *
 * Internal. Consuming `iconTypeToComponent` externally is discouraged.
 */
export const iconTypeToComponent = {
  add: AddIcon,
  alert: AlertIcon,
  'arrow-back': ArrowBackIcon,
  'arrow-forward': ArrowForwardIcon,
  book: BookIcon,
  bookmark: BookmarkIcon,
  'building-project': BuildingProjectIcon,
  calendar: CalendarIcon,
  'caret-down': CaretDownIcon,
  'caret-left': CaretLeftIcon,
  'caret-right': CaretRightIcon,
  'caret-up': CaretUpIcon,
  check: CheckIcon,
  'chevron-down': ChevronDownIcon,
  'chevron-left': ChevronLeftIcon,
  'chevron-right': ChevronRightIcon,
  'chevron-up': ChevronUpIcon,
  close: CloseIcon,
  coding: CodingIcon,
  'creative-project': CreativeProjectIcon,
  email: EmailIcon,
  empty: EmptyIcon,
  essays: EssaysIcon,
  exam: ExamIcon,
  'ext-link': ExtLinkIcon,
  file: FileIcon,
  fitness: FitnessIcon,
  'group-projects': GroupProjectsIcon,
  happy: HappyIcon,
  heart: HeartIcon,
  info: InfoIcon,
  labs: LabsIcon,
  menu: MenuIcon,
  neutral: NeutralIcon,
  'overflow-horizontal': OverflowHorizontalIcon,
  'overflow-vertical': OverflowVerticalIcon,
  presentations: PresentationsIcon,
  psets: PsetsIcon,
  'radio-empty': RadioEmptyIcon,
  'radio-filled': RadioFilledIcon,
  recent: RecentIcon,
  research: ResearchIcon,
  sad: SadIcon,
  search: SearchIcon,
  share: ShareIcon,
  'star-empty': StarEmptyIcon,
  'star-filled': StarFilledIcon,
  'star-half': StarHalfIcon,
  'thumb-down-filled': ThumbDownFilledIcon,
  'thumb-down-outline': ThumbDownOutlineIcon,
  'thumb-up-filled': ThumbUpFilledIcon,
  'thumb-up-outline': ThumbUpOutlineIcon,
  trash: TrashIcon,
  website: WebsiteIcon,
  write: WriteIcon,
}
