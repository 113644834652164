import classnames from 'classnames'
import { type ForwardedRef, forwardRef, type PropsWithChildren } from 'react'
import {
  type CuiThemeColor,
  type CuiThemeFontSize,
  type CuiThemeFontWeights,
} from 'src/cui/themes/types'
import styled, { css } from 'styled-components'

export type CuiTextSize = CuiThemeFontSize

type CuiTextColorWithoutInherit =
  | 'text'
  | 'accent'
  | 'constructive'
  | 'destructive'
  | 'hushed'
  | 'inverseText'
  | 'textOnDarkBackground'

export type CuiTextColor = 'inherit' | CuiTextColorWithoutInherit

/**
 *
 * Props for <CuiText>.
 */
type Props = {
  /**
   *
   * The size of the text.
   *
   * @default 'paragraph1'
   */
  size?: CuiTextSize

  /**
   *
   * Color of the text.
   *
   * @default 'text'
   */
  color?: CuiTextColor

  /**
   *
   * Wraps to specified number of lines, showing ellipsis for overflow.
   *
   * @default false
   */
  clamp?: false | number

  /**
   *
   * Whether to render the text in a `<span>`, `<div>` or a `<label>`
   *
   * @default 'span'
   */
  as?: 'span' | 'div' | 'label'
}

type TextColorMap = { [color in CuiTextColorWithoutInherit]: CuiThemeColor }

const textColorMap: TextColorMap = {
  text: 'text',
  accent: 'accent',
  constructive: 'constructive',
  destructive: 'destructive',
  hushed: 'lighterText',
  inverseText: 'inverseText',
  textOnDarkBackground: 'textOnDarkBackground',
}

type CuiTextRootProps = {
  $color: CuiTextColor
  $size: CuiTextSize
  $fontWeight: keyof CuiThemeFontWeights
  $clamp?: false | number
}

export const mixText = ({ $color, $size, $fontWeight }: CuiTextRootProps) => css`
  font-family: ${({ theme }) => theme.cuiFonts.regular};

  color: ${({ theme }) => ($color === 'inherit' ? 'inherit' : theme.cuiColors[textColorMap[$color]])};

  font-weight: ${({ theme }) => theme.cuiFontWeights[$fontWeight]};

  font-size: ${({ theme }) => theme.cuiFontSizes[$size]}px;
  line-height: ${({ theme }) => theme.cuiLineHeights[$size]}px;

  @media only screen and (max-width: ${({ theme }) => theme.cuiBreakpoints.tablet}px) {
    font-size: ${({ theme }) => theme.cuiMobileFontSizes[$size]}px;
    line-height: ${({ theme }) => theme.cuiMobileLineHeights[$size]}px;
  }
`

const CuiTextRoot = styled.div<CuiTextRootProps>`
  ${mixText}

  &.CuiTextRoot--label {
    font-variant: small-caps;
    letter-spacing: 0.02em;
  }

  ${({ $clamp }) =>
    $clamp &&
    `overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: ${$clamp};
  -webkit-box-orient: vertical;`};
`

const BOLDED_SIZES: CuiTextSize[] = ['titleDisplay', 'title1', 'title2', 'title3', 'title4', 'label']

/**
 *
 * `<CuiText>` renders text and headings
 */
function CuiTextImpl(
  { size = 'paragraph1', color = 'text', clamp = false, as, ...baseProps }: PropsWithChildren<Props>,
  ref: ForwardedRef<HTMLDivElement>
) {
  return (
    <CuiTextRoot
      ref={ref}
      {...baseProps}
      $size={size}
      $color={color}
      $fontWeight={BOLDED_SIZES.includes(size) ? 'semibold' : 'regular'}
      $clamp={clamp}
      className={classnames({
        'CuiTextRoot--label': size === 'label',
      })}
      as={as}
    />
  )
}

export const CuiText = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(CuiTextImpl)
