import { Fragment, type FunctionComponent, type PropsWithChildren } from 'react'
import { type APISchemas } from 'src/api/api'
import {
  friendlyGradingBasisText,
  requirementAbbreviation,
  seasonAbbreviation,
  stringifyUnits,
} from 'src/api/course'
import { CuiBadge, CuiFlexGrid, CuiFlexGroup, CuiReadMore, CuiSpacer, CuiText } from 'src/cui/components'

type Props = { course: APISchemas['MetaOffering'] }

export const CourseOverview: FunctionComponent<PropsWithChildren<Props>> = ({ course }) => {
  const { description, seasons, units, gradingBasis, requirements } = course

  return (
    <Fragment>
      <CuiText size='title2'>Class summary</CuiText>

      <CuiSpacer size='s' />

      <CuiReadMore>{description}</CuiReadMore>

      <CuiSpacer size='s' />

      <CuiFlexGrid columns={2}>
        <div>
          <CuiText size='title3'>Grading basis</CuiText>
          <CuiText size='paragraph1'>{friendlyGradingBasisText[gradingBasis.value]}</CuiText>
        </div>

        <div>
          <CuiText size='title3'>Units</CuiText>
          <CuiText size='paragraph1'>{stringifyUnits(units)}</CuiText>
        </div>

        <div>
          <CuiText size='title3'>Offered this year</CuiText>
          <CuiText size='paragraph1'>
            {seasons.map((season) => seasonAbbreviation[season]).join(' · ')}
          </CuiText>
        </div>

        <div>
          <CuiText size='title3'>GE requirements</CuiText>
          <CuiText size='paragraph1'>
            <CuiFlexGroup gutterSizeHorizontal='xs'>
              {requirements.value.map((requirement) => (
                <CuiBadge key={requirement}>
                  {requirementAbbreviation[requirement].toUpperCase()}
                </CuiBadge>
              ))}
            </CuiFlexGroup>
          </CuiText>
        </div>
      </CuiFlexGrid>
    </Fragment>
  )
}
