import classnames from 'classnames'
import { type MouseEvent, type PropsWithChildren } from 'react'
import { type CuiThemeColor, type CuiThemeFontSize } from 'src/cui/themes/types'
import { getSafeExternalLinkProps } from 'src/cui/utils'
import styled from 'styled-components'

import { type AsProps, type DefaultAsComponentProps } from '../../types/asProps'
import { CuiIcon, type CuiIconSize, type CuiIconType } from '../CuiIcon'
import { mixText } from '../CuiText'

type CuiButtonColor = 'primary' | 'accent'

type CuiButtonSize = 's' | 'm'

type CuiButtonIconSide = 'left' | 'right'

export type ButtonProps = {
  /**
   *
   * The size of the button.
   *
   * @default 'm'
   */
  size?: CuiButtonSize

  /**
   *
   * Color of the button.
   *
   * @default 'primary'
   */
  color?: CuiButtonColor

  /**
   *
   * Passed in classname.
   *
   */
  className?: string

  /**
   *
   * The icon type.
   */
  iconType?: CuiIconType

  /**
   *
   * The side the icon is on.
   *
   * @default 'left'
   */
  iconSide?: CuiButtonIconSide

  /**
   *
   * When `true`, prevents the user from interacting with the button
   *
   * @default false
   */
  disabled?: boolean

  /**
   *
   * A click event handler.
   *
   * @default undefined
   */
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
}

/**
 *
 * Props for <CuiButton>.
 */
export type Props<AsComponentProps = DefaultAsComponentProps> = PropsWithChildren<ButtonProps> &
  AsProps<AsComponentProps>

type SizeMap = {
  [color in CuiButtonSize]: {
    fontSize: CuiThemeFontSize
    verticalPaddingSize: string
    horizontalPaddingSize: string
    iconMarginSize: string
    iconSize: CuiIconSize
    iconOnlySize: string
  }
}

const sizeMap: SizeMap = {
  s: {
    fontSize: 'title4',
    verticalPaddingSize: '5px',
    horizontalPaddingSize: '12px',
    iconMarginSize: '4px',
    iconSize: 's',
    iconOnlySize: '32px',
  },
  m: {
    fontSize: 'title3',
    verticalPaddingSize: '8px',
    horizontalPaddingSize: '16px',
    iconMarginSize: '8px',
    iconSize: 'm',
    iconOnlySize: '44px',
  },
}

type ColorMap = {
  [color in CuiButtonColor]: {
    text: CuiThemeColor
    border?: CuiThemeColor
    background: CuiThemeColor
    disabledText: CuiThemeColor
    disabledBackground: CuiThemeColor
    disabledBorder: CuiThemeColor
    hoverOverlay: CuiThemeColor
    activeOverlay: CuiThemeColor
  }
}

const colorMap: ColorMap = {
  primary: {
    text: 'text',
    border: 'text',
    background: 'lightestShade',
    disabledText: 'disabled02',
    disabledBackground: 'lightestShade',
    disabledBorder: 'disabled02',
    hoverOverlay: 'overlayHover01',
    activeOverlay: 'overlayPressed01',
  },
  accent: {
    text: 'textOnDarkBackground',
    background: 'accent',
    disabledText: 'disabled02',
    disabledBackground: 'disabled01',
    disabledBorder: 'disabled01',
    hoverOverlay: 'overlayHover02',
    activeOverlay: 'overlayPressed02',
  },
}

type CuiButtonRootProps = {
  $color: CuiButtonColor
  $size: CuiButtonSize
}

export const CuiButtonRoot = styled.button<CuiButtonRootProps>`
  min-width: 0;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  align-items: center;
  text-decoration: none;

  color: ${({ theme, $color }) => theme.cuiColors[colorMap[$color].text]};
  ${({ theme, $color }) =>
    colorMap[$color].border
      ? `border: 1px solid ${theme.cuiColors[colorMap[$color].border!]};`
      : 'border-width: 0px;'};
  background-color: ${({ theme, $color }) => theme.cuiColors[colorMap[$color].background]};

  :hover:after {
    background-color: ${({ theme, $color }) => theme.cuiColors[colorMap[$color].hoverOverlay]};
  }

  :active:after {
    background-color: ${({ theme, $color }) => theme.cuiColors[colorMap[$color].activeOverlay]};
  }

  // todo make padding responsive
  padding: calc(
      ${({ $size }) => sizeMap[$size].verticalPaddingSize} +
        ${({ $color }) => (colorMap[$color].border ? '0px' : '1px')}
    )
    calc(
      ${({ $size }) => sizeMap[$size].horizontalPaddingSize} +
        ${({ $color }) => (colorMap[$color].border ? '0px' : '1px')}
    );

  ${({ $size }) =>
    mixText({ $color: 'inherit', $size: sizeMap[$size].fontSize, $fontWeight: 'semibold' })}

  :disabled {
    cursor: not-allowed;
    box-shadow: none;
    transform: none;

    color: ${({ theme, $color }) => theme.cuiColors[colorMap[$color].disabledText]} !important;
    border-color: ${({ theme, $color }) => theme.cuiColors[colorMap[$color].disabledBorder]};
    background-color: ${({ theme, $color }) => theme.cuiColors[colorMap[$color].disabledBackground]};

    :after {
      background-color: transparent !important;
    }
  }

  :after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    content: '';
  }

  &.CuiButtonRoot--iconOnly {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    border: none;
    padding: 0;

    width: ${({ $size }) => sizeMap[$size].iconOnlySize};
    height: ${({ $size }) => sizeMap[$size].iconOnlySize};
  }
`
type CuiIconButtonRootProps = {
  $size: CuiButtonSize
  $iconSide: CuiButtonIconSide
}

const CuiIconButtonRoot = styled.div<CuiIconButtonRootProps>`
  display: inline-flex;
  align-items: center; 
  margin-${({ $iconSide }) => ($iconSide === 'left' ? 'right' : 'left')}: ${({ $size }) =>
    sizeMap[$size].iconMarginSize}};

  &.CuiIconButtonRoot--iconOnly {
    margin: 0;
  }
`

/**
 *
 * <CuiButton> renders a button.
 * `react-router` links can be rendered like `<CuiButton as={Link} asProps={{ to: '/link' }}>`.
 */
export const CuiButton = <AsComponentProps,>({
  size = 'm',
  color = 'primary',
  iconType,
  iconSide = 'left',
  disabled = false,
  onClick,
  as = 'button',
  asProps = {} as any,
  children,
  className,
}: Props<AsComponentProps>) => {
  const iconOnly = children === undefined

  const icon = iconType ? (
    <CuiIconButtonRoot
      $size={size}
      $iconSide={iconSide}
      className={classnames({
        'CuiIconButtonRoot--iconOnly': iconOnly,
      })}
    >
      <CuiIcon type={iconType} size={sizeMap[size].iconSize} />
    </CuiIconButtonRoot>
  ) : undefined

  return (
    <CuiButtonRoot
      $color={color}
      $size={size}
      disabled={disabled}
      onClick={onClick}
      className={classnames(className, {
        'CuiButtonRoot--iconOnly': iconOnly,
      })}
      as={as}
      {...(asProps as any)}
      {...getExtraAsProps()}
    >
      {iconSide === 'left' && icon}

      {children}

      {iconSide === 'right' && icon}
    </CuiButtonRoot>
  )

  function getExtraAsProps() {
    if (as === 'button') {
      return {
        type: 'button',
      }
    }

    if (as === 'a') {
      return getSafeExternalLinkProps({ href: (asProps as any).href })
    }

    return {}
  }
}
