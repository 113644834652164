import { useQuery } from '@tanstack/react-query'

import { api, type APIRoutes, type APISchemas } from './api'

function getTerm(
  params: APIRoutes['/Term/Year/{endYear}']['get']['parameters']['path']
): Promise<APISchemas['CoursePlan']> {
  return api.get(`Term/Year/${params.endYear}`).json<any>()
}

export function useTerm(params: APIRoutes['/Term/Year/{endYear}']['get']['parameters']['path']) {
  return useQuery(['Term/Year/{endYear}', 'get', params], () => getTerm(params))
}
