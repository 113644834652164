import { useInfiniteQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { api, type APIRoutes, type APISchemas } from './api'
// type GetCourseReviews = { id: string; year: number }

// function getReview({ id, year }: GetCourseReviews): Promise<APISchemas['CourseReviewPageResults']> {
//   return api.get(`Course/${year}/${id}/Reviews`).json<APISchemas['CourseReviewPageResults']>()
// }

function getReviews(
  params: APIRoutes['/Course/{year}/{id}/Reviews']['get']['parameters']['path'],
  searchParams: APIRoutes['/Course/{year}/{id}/Reviews']['get']['parameters']['query']
): Promise<APISchemas['CourseReviewPageResults']> {
  return api
    .get(`Course/${params.year}/${params.id}/Reviews`, { searchParams })
    .json<APISchemas['CourseReviewPageResults']>()
}

export function useInfiniteReviews(
  params: APIRoutes['/Course/{year}/{id}/Reviews']['get']['parameters']['path'],
  searchParams: APIRoutes['/Course/{year}/{id}/Reviews']['get']['parameters']['query'] = {},
  options: { enabled?: boolean } = { enabled: true }
) {
  const { data: rawPages, ...rest } = useInfiniteQuery(
    ['/Course/2025/{id}/Reviews', 'get', params, searchParams],
    ({ pageParam = 1 }) => getReviews(params, { pageNumber: pageParam, ...searchParams }),
    {
      enabled: options.enabled,
      staleTime: Infinity,
      getNextPageParam: (lastPage) => lastPage.nextPage,
      getPreviousPageParam: (firstPage) => firstPage.previousPage,
    }
  )

  // console.log('rawPages', rawPages)
  const data = useMemo(
    () =>
      rawPages
        ? {
            totalEntryCount: rawPages.pages[0].totalEntryCount,
            reviews: rawPages.pages.flatMap((page) => page.entries),
          }
        : undefined,
    [rawPages]
  )

  return { data, ...rest }
}
