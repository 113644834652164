// Adapted from EuiFlexGroup (https://github.com/elastic/eui/blob/2f9977253869f312bbe1de88da37f577567eee19/src/components/flex/flex_group.tsx)

import classnames from 'classnames'
import { kebabCase } from 'lodash'
import { type FunctionComponent, type PropsWithChildren } from 'react'
import styled from 'styled-components'

import { type CuiThemeSpacingSizes } from '../../themes/types'

export type CuiFlexGroupAlignItems = 'stretch' | 'flexStart' | 'flexEnd' | 'center' | 'baseline'
export type CuiFlexGroupDirection = 'row' | 'rowReverse' | 'column' | 'columnReverse'
export type CuiFlexGroupJustifyContent =
  | 'flexStart'
  | 'flexEnd'
  | 'center'
  | 'spaceBetween'
  | 'spaceAround'
  | 'spaceEvenly'

export type CuiFlexGroupGutterSize = keyof CuiThemeSpacingSizes

/**
 *
 * Props for <CuiFlexGroup>.
 */
type Props = {
  /**
   *
   * How to align the contents of the group.
   *
   * @default 'center'
   */
  alignItems?: CuiFlexGroupAlignItems

  /**
   *
   * The direction the items are placed in the group.
   *
   * @default 'row'
   */
  direction?: CuiFlexGroupDirection

  /**
   *
   * How to justify the contents of the group.
   *
   * @default 'flexStart'
   */
  justifyContent?: CuiFlexGroupJustifyContent

  /**
   *
   * Margin between items
   *
   * @default 'none'
   */
  gutterSize?: CuiFlexGroupGutterSize

  /**
   *
   * Vertical margin between items
   *
   * @default the value of the `gutterSize` prop, defaults to 'none'.
   */
  gutterSizeVertical?: CuiFlexGroupGutterSize

  /**
   *
   * Horizontal margin between items
   *
   * @default the value of the `gutterSize` prop, defaults to 'none'.
   */
  gutterSizeHorizontal?: CuiFlexGroupGutterSize

  /**
   *
   * Whether to wrap the items of the group.
   *
   * @default false
   */
  wrap?: boolean

  /**
   *
   * Whether to render the text in a `<span>` or a `<div>`
   *
   * @default 'div'
   */
  as?: 'span' | 'div'
}

type CuiFlexGroupRootProps = {
  $alignItems: CuiFlexGroupAlignItems
  $direction: CuiFlexGroupDirection
  $justifyContent: CuiFlexGroupJustifyContent
  $gutterSizeVertical: CuiFlexGroupGutterSize
  $gutterSizeHorizontal: CuiFlexGroupGutterSize
}

const CuiFlexGroupRoot = styled.span<CuiFlexGroupRootProps>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction};
  align-items: ${({ $alignItems }) => kebabCase($alignItems)};
  justify-content: ${({ $justifyContent }) => kebabCase($justifyContent)};

  gap: ${({ theme, $gutterSizeVertical }) => theme.cuiSpacingSizes[$gutterSizeVertical] / 2}px
    ${({ theme, $gutterSizeHorizontal }) => theme.cuiSpacingSizes[$gutterSizeHorizontal] / 2}px;

  &.CuiFlexRoot--wrap {
    flex-wrap: wrap;
  }
`

/**
 *
 * `<CuiFlexGroup>` is useful for setting up layouts for a single row of content.
 */
export const CuiFlexGroup: FunctionComponent<PropsWithChildren<Props>> = ({
  alignItems = 'center',
  direction = 'row',
  justifyContent = 'flexStart',
  gutterSize = 'none',
  gutterSizeVertical = gutterSize,
  gutterSizeHorizontal = gutterSize,
  wrap = false,
  as = 'div',
  children,
}) => {
  return (
    <CuiFlexGroupRoot
      $alignItems={alignItems}
      $direction={direction}
      $justifyContent={justifyContent}
      $gutterSizeVertical={gutterSizeVertical}
      $gutterSizeHorizontal={gutterSizeHorizontal}
      as={as}
      className={classnames({
        'CuiFlexRoot--wrap': wrap === true,
      })}
    >
      {children}
    </CuiFlexGroupRoot>
  )
}
