import { Fragment, type FunctionComponent, type PropsWithChildren } from 'react'
import styled from 'styled-components'

import { CuiFlexGroup } from '../CuiFlexGroup'
import { CuiSpacer } from '../CuiSpacer'
import { CuiText } from '../CuiText'

/**
 * Props for <CuiSingleBarChart>
 */
type BarChartProps = {
  /**
   *
   * The label for the primary side (on the left)
   */
  primaryLabel: string

  /**
   *
   * The label for the secondary side (on the right)
   */
  secondaryLabel: string

  /**
   *
   * The perctentage of the primary/left side (a number 0 - 100)
   */
  primaryPercentage: number

  /**
   *
   * The height in pixels of the bar
   *
   * @default 56
   */
  height?: number
}

/**
 * Props for <SingleBarChartLabel>
 */
type LabelProps = {
  /**
   *
   * Label
   */
  label: string

  /**
   *
   * Percentage associated with label
   */
  percentage: number

  /**
   *
   * Text-align right or not
   *
   * @default false
   */
  alignRight?: boolean
}

const ROUND_TO = 10 // nearest tenth

const HEIGHT = 56

type CuiSingleBarChartWrapperProps = { $height: number }

const CuiSingleBarChartWrapper = styled.div<CuiSingleBarChartWrapperProps>`
  background-color: ${({ theme }) => theme.cuiVizColors.dataViz07};
  height: ${(props) => props.$height}px;
  position: relative;
  width: 100%;
`

type CuiSingleBarChartPrimaryProps = { $height: number; $percentFilled: number }

const CuiSingleBarChartPrimary = styled.div<CuiSingleBarChartPrimaryProps>`
  background-color: ${({ theme }) => theme.cuiVizColors.dataViz01};
  height: ${(props) => props.$height}px;
  position: absolute;
  width: ${(props) => props.$percentFilled}%;
`

type CuiLabelFlexGroupProps = { $alignRight: boolean }

const CuiLabelFlexGroup = styled.div<CuiLabelFlexGroupProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: ${(props) => (props.$alignRight ? 'right' : 'left')};
`

const ChartLabel: FunctionComponent<PropsWithChildren<LabelProps>> = ({
  label,
  percentage,
  alignRight = false,
}) => {
  return (
    <CuiLabelFlexGroup $alignRight={alignRight}>
      <CuiText color='text' size='paragraph2' as='div'>
        {label}
      </CuiText>
      <CuiText color='text' size='title3'>
        {percentage}%
      </CuiText>
    </CuiLabelFlexGroup>
  )
}

export const CuiSingleBarChart: FunctionComponent<PropsWithChildren<BarChartProps>> = ({
  primaryLabel,
  secondaryLabel,
  primaryPercentage,
  height = HEIGHT,
}) => {
  const percentFilled = Math.min(100, Math.round(primaryPercentage * ROUND_TO) / ROUND_TO)

  return (
    <Fragment>
      <CuiSingleBarChartWrapper $height={height}>
        <CuiSingleBarChartPrimary $height={height} $percentFilled={percentFilled} />
      </CuiSingleBarChartWrapper>
      <CuiSpacer size='xs' />
      <CuiFlexGroup justifyContent='spaceBetween' alignItems='center'>
        <ChartLabel label={primaryLabel} percentage={percentFilled} />
        <ChartLabel
          label={secondaryLabel}
          percentage={Math.round((100 - primaryPercentage) * ROUND_TO) / ROUND_TO}
          alignRight={true}
        />
      </CuiFlexGroup>
    </Fragment>
  )
}
