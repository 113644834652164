import { type PropsWithChildren } from 'react'
import styled from 'styled-components'

import { CuiButton } from '../CuiButton'
import type { ButtonProps } from '../index'

type Props = ButtonProps & {
  /**
   *
   * `true` when selected styling is desired
   *
   * @default false
   */
  selected?: boolean
}

/** This is an internal component to Cui and is not meant to be consumed outside of Cui */
const StyledCuiButton = styled(CuiButton)<{ $selected: boolean }>`
  background-color: ${({ theme, $selected }) =>
    $selected ? theme.cuiColors.overlayAccent : theme.cuiColors.lightestShade};

  border: 1px solid
    ${({ theme, $selected }) => ($selected ? theme.cuiColors.accent : theme.cuiColors.text)};

  color: ${({ theme, $selected }) => ($selected ? theme.cuiColors.accent : theme.cuiColors.text)};

  :hover:after {
    background-color: ${({ theme, $selected }) =>
      $selected ? theme.cuiColors.overlayAccentHover : theme.cuiColors.overlayHover01};
  }

  :active:after {
    background-color: ${({ theme, $selected }) =>
      $selected ? theme.cuiColors.overlayAccentPressed : theme.cuiColors.overlayPressed01};
  }
`

export const CuiFilterButton = ({ selected = false, children, onClick }: PropsWithChildren<Props>) => {
  return (
    <StyledCuiButton $selected={selected} iconType='caret-down' iconSide='right' onClick={onClick}>
      {children}
    </StyledCuiButton>
  )
}
